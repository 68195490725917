import React from "react";
import Img1 from "../../images/Group 23.svg";
import Img2 from "../../images/Group.svg";
import Img3 from "../../images/Vector (1).svg";
import Img4 from "../../images/Vector (2).svg";
import Img5 from "../../images/Vector (3).svg";
import Img6 from "../../images/Vector (4).svg";
import CountUp from 'react-countup';


const Courses = () => {

  // useCountUp({
  //   ref: 'counter',
  //   end: 1234567,
  //   enableScrollSpy: true,
  //   scrollSpyDelay: 1000,
  // });


  return (
    <section className={` md:px-0`}>
      <div className={`flex flex-col lg:flex-row justify-center `}>
        <div className={`bg-[#914E1D] px-8 font-face-hl py-[4rem] md:py-24 md:px-16`}>
          <img src={Img1} alt="" className={`w-16 h-16 mb-4`} />
          <h1
            className={`mb-4 text-[38px] leading-[45px] text-white font-light`}
          >
            Practical Videos
          </h1>
          <p className={`text-[16px] text-white leading-[32px]`}>
            Get beyond just theory with Agrolearn's practical videos. Our
            courses offer hands-on experience and application so you can put
            your knowledge into practice
          </p>
        </div>

        <div className={`bg-heading px-8 font-face-hl py-[4rem] md:py-24 md:px-16`}>
          <img src={Img2} alt="" className={`w-16 h-16 mb-4`} />
          <h1
            className={`mb-4 text-[38px] leading-[45px] text-white font-light`}
          >
            Global Standards
          </h1>
          <p className={`text-[16px] text-white leading-[32px]`}>
            Gain a competitive edge with our courses that meet global standards.
            Our curriculum is developed in partnership with leading institutions
            around the world, giving you the knowledge and expertise to succeed
            in the global market
          </p>
        </div>

        <div className={`bg-main-green px-8 font-face-hl py-[4rem] md:py-24 md:px-16`}>
          <img src={Img3} alt="" className={`w-16 h-16 mb-4`} />
          <h1
            className={`mb-4 text-[38px] leading-[45px] text-white font-light`}
          >
            Certificate
          </h1>
          <p className={`text-[16px] font-normal text-white leading-[32px]`}>
            Showcase your expertise with our internationally recognized
            certificates. Our courses provide you with the credentials you need
            to stand out in the job market and advance your career
          </p>
        </div>
      </div>


      <div className={`bg-white font-face-hl`}>
        <div className={`py-20 px-8`}>
          <div className={`text-center mb-6`}>
            <h1
              className={`font-face-mg text-main-green text-[38px] leading-[41px] mb-6`}
            >
              Our Numbers
            </h1>
            <p className={`text-[18px] leading-[20px] text-[#18191F]`}>
              We have made commendable progress and we have proof.
            </p>
          </div>
          <div
            className={`grid grid-cols-2 gap-4  lg:grid-cols-4 lg:w-[80%] lg:mx-auto`}
          >
            <div
              className={`flex justify-between items-center lg:w-[120px] mx-auto gap-4`}
            >
              <img src={Img4} alt="" className={`w-14 h-14`} />
              <div className={``}>

              <CountUp start={0} end={30} delay={0} enableScrollSpy scrollSpyDelay={1000}>
                {({ countUpRef }) => (
                  <div className="flex items-center space-x-1">
                     <span
                     ref={countUpRef } 
                  className={`font-bold text-[28px] md:text-[36px] leading-[46px] text-[#18191F]`}
                /> 

                <span  className={`font-bold text-[28px] md:text-[36px] leading-[46px] text-[#18191F]`}>
                  +
                </span>
              
                  </div>
                )}
              </CountUp>
               
              
                <p className={`text-[18.3px] leading-[20px] text-[#18191F]`}>
                  Courses
                </p>
              </div>
            </div>

            
            <div
              className={`flex justify-between items-center lg:w-[120px] mx-auto gap-4`}
            >
              <img src={Img5} alt="" className={`w-14 h-14`} />
              <div className={``}>
              <CountUp start={0} end={5000} delay={0} enableScrollSpy scrollSpyDelay={1000}>
                {({ countUpRef }) => (
                  <div className="flex items-center space-x-1">
                     <span
                     ref={countUpRef } 
                  className={`font-bold text-[28px] md:text-[36px] leading-[46px] text-[#18191F]`}
                /> 

                <span  className={`font-bold text-[28px] md:text-[36px] leading-[46px] text-[#18191F]`}>
                  +
                </span>
              
                  </div>
                )}
              </CountUp>
               
                <p className={`text-[18.3px] leading-[20px] text-[#18191F]`}>
                  Learners
                </p>
              </div>
            </div>
            <div
              className={`flex justify-between items-center lg:w-[120px] mx-auto gap-4`}
            >
              <img src={Img2} alt="" className={`w-14 h-14`} />
              <div className={``}>
              <CountUp start={0} end={23} delay={0} enableScrollSpy scrollSpyDelay={1000}>
                {({ countUpRef }) => (
                  <div className="flex items-center space-x-1">
                     <span
                     ref={countUpRef } 
                  className={`font-bold text-[28px] md:text-[36px] leading-[46px] text-[#18191F]`}
                /> 

                <span  className={`font-bold text-[28px] md:text-[36px] leading-[46px] text-[#18191F]`}>
                  +
                </span>
              
                  </div>
                )}
              </CountUp>
                <p className={`text-[18.3px] leading-[20px] text-[#18191F]`}>
                  Countries reached
                </p>
              </div>
            </div>
            <div
              className={`flex justify-between items-center lg:w-[120px] mx-auto gap-4`}
            >
              <img src={Img6} alt="" className={`w-14 h-14`} />
              <div className={`w-[80px]`}>
              <CountUp start={0} end={700} delay={0} enableScrollSpy scrollSpyDelay={1000}>
                {({ countUpRef }) => (
                  <div className="flex items-center space-x-1">
                     <span
                     ref={countUpRef } 
                  className={`font-bold text-[28px] md:text-[36px] leading-[46px] text-[#18191F]`}
                /> 

                <span  className={`font-bold text-[28px] md:text-[36px] leading-[46px] text-[#18191F]`}>
                  +
                </span>
              
                  </div>
                )}
              </CountUp>
                <p className={`text-[18.3px] leading-[20px] text-[#18191F]`}>
                  Businesses supported
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Courses;
