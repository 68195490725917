import React, {useEffect} from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState
} from 'react-accessible-accordion';

import faqs from '../data/faq';


import 'react-accessible-accordion/dist/fancy-example.css';

const Faq = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);
  return (
    <section className={`font-face-hl pt-[7rem]  md:pt-[2rem]`} >
    <div
        className={`bg-main-green  py-12 px-8 text-center h-[250px] md:h-[371px] flex items-center justify-center md:px-16 mb-6`}
      >
        <div className={`mt-10 md:mt-16 `}>
          <h1
          className={`font-face-mg font-bold text-[40px] text-heading leading-[79.45px] mb-2 md:text-[50px] md:leading-[158.9%]`}
        >
          FAQ
        </h1>
     
        </div>
        
    
        
      </div>

      <div className="px-8 lg:px-16 mb-10">
      <Accordion allowZeroExpanded>
    {faqs.map((item) => (
        <AccordionItem key={item.uuid} className="border-none">
            <AccordionItemHeading >
                <AccordionItemButton  className="bg-[#EFF6FD]  py-4 px-3 rounded-sm flex items-center justify-between mb-4 font-face-hl text-[18px]">
                 <h1>  {item.question}</h1> 
                    <AccordionItemState>
                  {({expanded}) => (expanded ?  (<i class="fa-solid fa-chevron-up"></i> ) : ( <i class="fa-solid fa-chevron-down"></i>))}
                </AccordionItemState>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel >
              {item.answer}
            </AccordionItemPanel>
        </AccordionItem>
    ))}
</Accordion>
      </div>

     
      </section>
  )
}

export default Faq