 const faqs = [
    {
        id: 0,
        question: 'What is Agrolearn?',
        answer: 'Agrolearn is an e-learning platform that offers courses on agriculture-related topics. The platform is designed for Agric students, farmers, agribusiness owners, and anyone who is interested in learning about agriculture.'
    },
    {
        id: 1,
        question: 'What courses does Agrolearn offer?',
        answer: 'Agrolearn offers a wide range of courses on topics such as agribusiness financing, soilless farming technology, Agric extension, Digital Marketing for Agribusinesses, Commodity trading,  Corporate Soft Skills, and more.'
    },
    {
        id: 2,
        question: 'Who can take courses on Agrolearn?',
        answer: 'Anyone can take courses on Agrolearn, regardless of their educational background or level of experience in agriculture. Our courses are designed to be accessible and user-friendly for everyone.'
    },

    {
        id: 3,
        question: 'How do I enroll in a course on Agrolearn?',
        answer: 'To enroll in a course on Agrolearn, simply create an account on our website and browse our course catalog. Once you\'ve found a course you\'re interested in, you can enroll and start learning right away.'
    },
    {
        id: 4,
        question: 'Are the courses on Agrolearn self-paced?',
        answer: 'Yes, all courses on Agrolearn are self-paced, meaning you can complete them at your own pace and on your own schedule.'
    },
    {
        id: 5,
        question: 'What kind of learning materials are included in the courses on Agrolearn?',
        answer: 'Our courses include a variety of learning materials such as video lectures, interactive quizzes, readings, and assignments.'
    },
    {
        id: 6,
        question: 'Can I interact with other learners on Agrolearn?',
        answer: 'Yes, you can interact with other learners on Agrolearn through our discussion forums and community groups.'
    },
    {
        id:7,
        question: 'Are the courses on Agrolearn accredited?',
        answer: 'Our courses are not accredited, but they are developed by experts in the agriculture industry and are designed to provide practical, real-world knowledge and skills.'
    },
    {
        id: 8,
        question: 'How much do the courses on Agrolearn cost?',
        answer: 'The cost of our courses varies depending on the course and its length. Some courses are available for free, while others require payment. You can find the cost of each course on our website'
    },
    {
        id: 9,
        question: 'Is technical support available for learners on Agrolearn?',
        answer: 'Yes, we provide technical support for all learners on Agrolearn. If you encounter any technical issues while taking our courses, you can contact our support team for assistance.'
    },
 ];



 export default faqs;