import Img1 from "../images/farmer5.svg";
import Img2 from "../images/chidinma.jpeg";
import Img3 from "../images/farmer4.svg";
import Img4 from "../images/pexels-gustavo-fring-4173177 2.svg";

const story = [
  {
    id: 0,
    img: Img1,
    text: "Danlandi is a young boy from Kano state, Nigeria who while growing up saw his father's unbridled passion for agriculture which fueled his interest in the field. Unfortunately, he lost his father at a tender age of 9. His fathers demise aborted his learning; irrespective, Danlandi still nurses his passion for agriculture and wants to learn more to become an expert.",
  },
  {
    id: 1,
    img: Img2,
    text: "Chidinma is an agricultural extension student in one of the leading universities in West Africa. She is passionate about agriculture and thirsts to be deeply grounded in the field. She scouts for opportunities that will enable her to garner the requisite knowledge required to excel as an extension agent, network and work with farmers across the world. To grow her knowledge, Chidinma took a course on the platform and passed with flying colors, which qualified her for an internship placement. This internship was a game-changer for Chidinma as it helped her build capacity, grow valuable networks, and scale her competence.",
  },
  {
    id: 2,
    img: Img3,
    text: "Abayomi is a small holder farmer from South-West Nigeria. He experiences diverse challenges on his farm on a daily basis and lacks the required knowledge to tackle them as they arise hence rendering him less productive. He also lacks the required finance needed to engage a seasoned consultant but still needs a credible and affordable avenue to learn and optimize his business operations.",
  },
  {
    id: 3,
    img: Img4,
    text: "David is a professional with over 20 years of experience in financial management. He's passionate about teaching what he knows to other people. He loves to make impact, and an avenue to earn from it interests him.",
  },

];

export default story;
