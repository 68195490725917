import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import "./fonts/Helvetica/Helvetica-Bold.ttf";
import "./fonts/Helvetica/Helvetica.ttf";
import "./fonts/Mogena/Mogena.ttf"
import 'react-tabs/style/react-tabs.css';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import 'react-lazy-load-image-component/src/effects/blur.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

