//import React, { useRef, useState } from "react";
import testimonialData from "../../data/testimonial";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
// import "swiper/css";
// import "swiper/css/scrollbar";
// import { Scrollbar } from "swiper";

const Testimonial = () => {
  return (
    <section className={`bg-main-green py-10 md:px-16`}>
      <div className={`text-center`}>
        <h1
          className={`text-main-lime-green font-face-mg text-[38px] leading-[41px] mb-6`}
        >
          Testimonials
        </h1>
        <p className={`text-[18px] leading-[20px] text-[#fff] mb-8`}>
          What our learners and instructors say about us.
        </p>
      </div>

      <Swiper
        spaceBetween={50}
        slidesPerView={3}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Navigation, Autoplay]}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },

          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
      >
        {testimonialData.map((data) => (
          <SwiperSlide key={data.id}>
            <div
              className={`p-[2rem] bg-white  mx-auto mb-[2rem] md:h-[auto] lg:h-[500px]`}
            >
              <p
                className={`font-face-mg text-base font-normal leading-[30px] text-main-green text-center`}
              >
                {data.title}
              </p>
              <p
                className={`font-face-hl text-base leading-[30px] text-black text-justify`}
              >
                {data.description}
              </p>
              <div
                className={`flex justify-between items-center font-face-hl pt-6`}
              >
                <p
                  className={`text-main-green text-[14px] font-bold leading-[16px]`}
                >
                  {data.name}
                </p>
                <img
                  src={data.img}
                  alt=""
                  className={`w-20 h-20 rounded-full`}
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Testimonial;
