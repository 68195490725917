import React, {useEffect} from "react";
import { Link } from "react-router-dom";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);
  return (
    <section className={`font-face-hl pt-[7rem] md:pt-[2rem]`}>
      <div
        className={`bg-main-green  py-12 px-8 text-center h-[250px] md:h-[371px] flex items-center justify-center md:px-16 mb-6`}
      >
        <div className={`mt-10 md:mt-16 `}>
          <h1
            className={`font-face-mg font-bold text-[40px] text-heading leading-[79.45px] mb-2 md:text-[50px] md:leading-[158.9%]`}
          >
            Terms of Use
          </h1>
        </div>
      </div>

      <div className=" px-8 lg:px-16 text-[18px] leading-[35px] py-10">
        <h4 className="text-[25px] font-semibold">Welcome to Agrolearn! </h4>
        <p className="">
          Agrolearn{" "}
          <span className="text-main-green underline">(www.agrolearn.org)</span>{" "}
          is an e-learning platform that focuses on bridging the skills gap of
          the next generation of African agrarian leaders by providing access to
          self-paced courses offered by credible organizations, professionals,
          and institutions which are made available in English and African
          languages. These African agrarian leaders include agricultural
          students, agricultural entrepreneurs, farmers, professionals, and
          agricultural enthusiasts. Our courses span across various categories,
          some of which are food nutrition and security, communication and
          media, legal and tax laws for businesses, food production, and
          processing, career-related courses amongst others (the “Services”).
          The Services may be offered online (subscription via our Site to
          courses), offline (sale of courses in memory card sticks or flash
          drives), or physical training (which is available on request). We also
          provide learners access to internship placement upon fulfillment of
          the eligibility criteria and opening at our partner organisations.{" "}
          <br />
        </p>
        <br></br>

        <p>
          <span className="text-main-green underline">
            www.agrolearn.org (our “Site”){" "}
          </span>
          is owned and operated by Agrolearn Limited, a company registered in
          Nigeria with registration number [1814240] with offices at Rostal
          Suite, Press House 27, Acme Road, Agidingbi, Ikeja, Lagos State,
          Nigeria ("Agrolearn Limited ", or “Agrolearn” "we", "us" or "our").{" "}
          <br />
        </p>

        <p>
          For the purpose of these Terms of Use (“Terms”) and our Privacy Policy
          along with any amendments, “You”, “Your”, or “User” refers to the
          person visiting, accessing, browsing through our Site and/or using our
          Services.
        </p>
        <br />
        <p>
          These Terms govern Your use of our Site and Services. Our Privacy
          Policy also governs Your use of our Site and explains how we collect,
          safeguard and disclose Your information. Please read it{" "}
          <Link className="underline text-main-green" to="/privacy-policy">
            here
          </Link>
          .{" "}
        </p>
        <br />

        <p>
          Please read these Terms and our Privacy Policy with respect to the
          access and use of our Site and Services carefully before using our
          Site or Services. If You do not agree with these Terms or our Privacy
          Policy, please do not use our Services. In the event of any
          discrepancy between these Terms and any other policies with respect to
          our Services, the provisions of these Terms shall prevail.
        </p>
        <br />

        <ul className="list-decimal space-y-4 text-[18px] px-4">
          <li>
            <b>Agreement to Terms </b>
            <br />
            These Terms constitute a legally binding agreement made between You,
            whether personally or on behalf of an entity and Agrolearn
            concerning Your access to and use of our Site as well as any other
            media form, media channel linked, or otherwise connected thereto.
            You agree that by accessing our Site, You have read, understood, and
            agree to be bound by all of these Terms. If You do not agree with
            all of these Terms, then You are expressly prohibited from using the
            Site and You must discontinue use immediately. Supplemental terms
            and conditions or documents that may be posted on our Site from time
            to time are hereby expressly incorporated herein by reference. We
            reserve the right, in our sole discretion, to make changes or
            modifications to these Terms at any time and for any reason. We will
            alert You about any changes by updating the “Last updated” date of
            these Terms, and You waive any right to receive specific notice of
            each such change. It is Your responsibility to periodically review
            these Terms to stay informed of updates. You will be subject to and
            will be deemed to have been made aware of and to have accepted, the
            changes in any revised Terms by Your continued use of our Site after
            the date such revised Terms are posted. The information provided on
            our Site is not intended for distribution to or use by any person or
            entity in any jurisdiction or country where such distribution or use
            would be contrary to law or regulation or which would subject us to
            any registration requirement within such jurisdiction or country.
            Accordingly, those persons who choose to access our Site from other
            locations do so on their own initiative and are solely responsible
            for compliance with local laws, if and to the extent local laws are
            applicable. <br />
          </li>

          <li>
            <b>Minimum Age</b>
            <br></br>
            Our Site is intended for Users who are at least 18 years of age. By
            accessing or using our Site, You warrant and represent that You are
            at least 18 years of age and with the full authority, right, and
            capacity to enter into this agreement and abide by all of the terms
            and conditions of Terms. However, all Users who are minors in the
            jurisdiction in which they reside (generally under the age of 18)
            must have the permission of, and be directly supervised by, their
            parent or guardian to use our Site. If You are a minor, You must
            have Your parent or guardian read and agree to these Terms prior to
            You using our Site. If You are acting on behalf of a minor, the
            minor is also bound by these Terms.
          </li>

          <li>
            <b>Registration </b>
            <br />
            3.1 In order to access our Services, You may be required to register
            with our Site. You agree to keep Your password confidential and will
            be responsible for all use of Your account and password. We reserve
            the right to remove, reclaim, or change a username You select if we
            determine, in our sole discretion, that such username is
            inappropriate, obscene, or otherwise objectionable By using our
            Site, You represent and warrant that: <br />
            (i) all registration information You submit will be true, accurate,
            current, and complete; <br />
            (ii) You will maintain the accuracy of such information and promptly
            update such registration information as necessary; <br />
            (iii) You have the legal capacity and You agree to comply with these
            Terms; <br />
            (v) You are not a minor in the jurisdiction in which You reside, or
            if a minor, You have received parental permission to use our Site;{" "}
            <br />
            (vi) You will not access our Site through automated or non-human
            means, whether through a bot, script, or otherwise; <br />
            (vii) You will not use our Site for any illegal or unauthorized
            purpose; and
            <br />
            (viii) Your use of the Site will not violate any applicable law or
            regulation.
            <br />
            Should You provide any information that is untrue, inaccurate, not
            current, or incomplete, we have the right to suspend or terminate
            Your account and refuse any and all current or future use of our
            Site (or any portion thereof). <br />
            3.2 You are responsible for maintaining the confidentiality of Your
            account and password, including but not limited to the restriction
            of access to Your computer and/or account. You agree to accept
            responsibility for any and all activities or actions that occur
            under Your account and/or password, whether Your password is with
            our Site or a third-party site. You must notify us immediately upon
            becoming aware of any breach of security or unauthorized use of Your
            account. <br />
            3.3 You may not use as a username the name of another person or
            entity or that is not lawfully available for use, a name or
            trademark that is subject to any rights of another person or entity
            other than You, without appropriate authorization. You may not use
            as a username any name that is offensive, vulgar or obscene. <br />
            We reserve the right to refuse service, terminate accounts, remove
            or edit user accounts in our sole discretion.
          </li>

          <li>
            <b>Intellectual Property Rights</b> <br />
            4.1 Unless otherwise indicated, our Site is our proprietary property
            and all source code, databases, functionality, software, website
            designs, audio, video, text, photographs, and graphics on our Site
            (collectively referred to as the “Content”) and the trademarks,
            service marks, and logos contained therein (the “Marks”) are owned
            or controlled by us or licensed to us, and are protected by
            copyright and trademark laws and various other intellectual property
            rights and laws of the Federal Republic of Nigeria. The Content and
            the Marks are provided on our Site as is for Your information and
            personal use only. Except as expressly provided in these Terms, no
            part of our Site and no Content or Marks may be copied, reproduced,
            aggregated, republished, uploaded, posted, publicly displayed,
            encoded, translated, transmitted, distributed, sold, licensed, or
            otherwise exploited for any commercial purpose whatsoever, without
            our express prior written permission. We reserve all rights not
            expressly granted to You in and to our Site, the Content and the
            Marks. <br />
            4.2 We respect the intellectual property rights of others. It is our
            policy to respond to any claim that Content on our Site infringes on
            the copyright or other intellectual property rights (“Infringement”)
            of any person or entity. If You believe that any portion of the
            Content on our Site constitutes an intellectual property
            infringement, please submit Your claim via email to
            dpo@agrolearn.org, with the subject line: “Intellectual Property
            Infringement” and include a detailed description of the alleged
            Infringement. Please note that You may be held accountable for
            damages (including costs and legal fees) for misrepresentation or
            bad-faith claims on the infringement of any Content found on and/or
            through our Site and Services on Your intellectual property rights.
          </li>

          <li>
            <b>Communications </b> <br />
            By registering on our Site and creating a user account, You agree to
            subscribe to newsletters, marketing or promotional materials and
            other information we may send. However, You may opt out of receiving
            any, or all, of these communications from us by following the
            unsubscribe link or by emailing the Agrolearn team at
            help@agrolearn.org.
          </li>

          <li>
            <b>Purchases </b>
            <br />
            6.1 If You wish to purchase access to a course made available
            through our Services (a “Purchase”), You may be asked to supply
            certain information relevant to Your Purchase including, without
            limitation, Your debit/credit card number, the expiration date of
            Your debit/credit card and Your billing address. You represent and
            warrant that: <br />
            (i) You have the legal right to use any debit/credit card(s) or
            other payment method(s) in connection with any Purchase; and <br />
            (ii) the information You supply to us is true, correct and complete.{" "}
            <br />
            6.2 We may employ the use of third party services for the purpose of
            facilitating payment and the completion of Purchases. By submitting
            Your information, You grant us the right to provide the information
            to these third parties subject to our Privacy Policy. <br />
            6.3 We reserve the right to refuse or cancel Your order at any time
            for reasons including but not limited to: availability of course
            content, errors in the description or price of the course or other
            reasons subject to our discretion. <br />
            We also reserve the right to refuse or cancel Your Purchase if fraud
            or an unauthorized or illegal transaction is suspected.
          </li>

          <li>
            <b>Contests, Sweepstakes and Promotions </b>
            <br />
            7.1 Any contests, discounts, sweepstakes or other promotions
            (collectively, “Promotions”) made available through our Site may be
            governed by rules that are separate from these Terms. If You
            participate in any Promotions, please review the applicable rules as
            well as our Privacy Policy. If the rules for a Promotion conflict
            with these Terms, Promotion rules will apply. <br />
            7.2 Our decisions as to the winners or awardees of any Promotions
            are final and are not subject to review or appeal.
          </li>

          <li>
            <b>Subscriptions </b>
            <br />
            8.1 Some parts of our Services may be billed on a subscription basis
            (“Subscription(s)”). You will be billed in advance on a recurring
            and periodic basis (“Billing Cycle”). Billing cycles are set on a
            monthly basis. At the end of each Billing Cycle, Your Subscription
            will automatically renew under the exact same conditions unless You
            cancel it or Agrolearn cancels it. You may cancel Your Subscription
            renewal either through Your online account management page or by
            contacting the Agrolearn customer support team at{" "}
            <a
              classname="text-main-green underline"
              href="mailto:help@agrolearn.org"
            >
              help@agrolearn.org.
            </a>{" "}
            <br />
            8.2 A valid payment method, including debit/credit card information,
            is required to process the payment for Your subscription. You shall
            provide Agrolearn with accurate and complete billing information
            including Your full name, address, telephone number, and valid
            payment method information. By submitting such payment information,
            You automatically authorize Agrolearn to charge all Subscription
            fees incurred through Your account to any such payment instruments.{" "}
            <br />
            8.3 Should automatic billing fail to occur for any reason, Agrolearn
            will issue an electronic invoice indicating that You must proceed
            manually, within a certain deadline date, with the full payment
            corresponding to the billing period as indicated on the invoice.
          </li>

          <li>
            <b>Fee Changes </b>
            <br />
            9.1 Agrolearn, in its sole discretion and at any time, may modify
            the fees for our Services. We will provide You with a reasonable
            prior notice of any change in our fees to give You an opportunity to
            terminate Your registration with us before such change becomes
            effective. <br />
            9.2 Your continued use of our Services after the fee change comes
            into effect constitutes Your agreement to pay the modified fee
            amount.
          </li>

          <li>
            <b>Refunds </b> <br />
            Except when required by law, fees paid are non-refundable.
          </li>

          <li>
            <b>Prohibited Uses </b> <br />
            11.1 You may use our Site and Services only for lawful purposes and
            in accordance with these Terms. You agree not to use our Site and
            Services: <br />
            a. In any way that violates any applicable national or international
            law or regulation. <br />
            b. For the purpose of exploiting, harming, or attempting to exploit
            or harm minors in any way by exposing them to inappropriate content
            or otherwise. <br />
            c. To transmit, or procure the sending of, any advertising or
            promotional material, including any “junk mail”, “chain letter,”
            “spam,” or any other similar solicitation. <br />
            d. To impersonate or attempt to impersonate Agrolearn, an employee
            of Agrolearn, another user, or any other person or entity. <br />
            e. In any way that infringes upon the rights of others, or in any
            way is illegal, threatening, fraudulent, or harmful, or in
            connection with any unlawful, illegal, fraudulent, or harmful
            purpose or activity. <br />
            f. To engage in any other conduct that restricts or inhibits
            anyone’s use or enjoyment of our Site and Services, or which, as
            determined by us, may harm or offend Agrolearn or users of our Site
            and Services or expose them to liability. <br />
            11.2 Additionally, You agree not to: <br />
            a. Use our Site and Services in any manner that could disable,
            overburden, damage, or impair our Site and Services or interfere
            with any other party’s use of our Site and Services, including their
            ability to engage in real time activities through our Site. <br />
            b. Use any robot, spider, or other automatic device, process, or
            means to access our Site and Services for any purpose, including
            monitoring or copying any of the material on our Site and Services.{" "}
            <br />
            c. Use any manual process to monitor or copy any of the material on
            our Site and Services or for any other unauthorized purpose without
            our prior written consent. <br />
            d. Use any device, software, or routine that interferes with the
            proper working of our Site and Services. <br />
            e. Introduce any viruses, trojan horses, worms, logic bombs, or
            other material which is malicious or technologically harmful. <br />
            f. Attempt to gain unauthorized access to, interfere with, damage,
            or disrupt any parts of our Site and Services, the server on which
            our Site is stored, or any server, computer, or database connected
            to our Site. <br />
            g. Attack our Site via a denial-of-service attack or a distributed
            denial-of-service attack.
            <br />
            h. Take any action that may damage or falsify Agrolearn’s applicable
            performance ratings. <br />
            i. Otherwise attempt to interfere with the proper working of our
            Site and Services.
          </li>

          <li>
            <b>Links To Third Party Web Sites </b> <br />
            Our Site may contain links to third party web sites or services that
            are not owned or controlled by Agrolearn. Agrolearn has no control
            over, and assumes no responsibility for the content, privacy
            policies, or practices of any third party web sites or services. We
            do not warrant the offerings of any of these entities/individuals or
            their websites. YOU ACKNOWLEDGE AND AGREE THAT AGROLEARN SHALL NOT
            BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR
            LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF
            OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR
            THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES. WE STRONGLY
            ADVISE YOU TO READ THE TERMS OF USE AND PRIVACY POLICIES OF ANY
            THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.
          </li>

          <li>
            <b>Disclaimer of Warranty</b>
            <br />
            OUR SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS.
            AGROLEARN MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
            EXPRESS OR IMPLIED, AS TO THE OPERATION OF OUR SERVICES, OR THE
            INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY
            AGREE THAT YOUR USE OF OUR SERVICES, OUR CONTENT, AND ANY SERVICES
            OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK. NEITHER AGROLEARN
            NOR ANY PERSON ASSOCIATED WITH AGROLEARN MAKES ANY WARRANTY OR
            REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
            RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES.
            WITHOUT LIMITING THE FOREGOING, NEITHER AGROLEARN NOR ANYONE
            ASSOCIATED WITH AGROLEARN REPRESENTS OR WARRANTS THAT OUR SERVICES,
            OUR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH OUR SERVICES
            WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT
            DEFECTS WILL BE CORRECTED, THAT OUR SITE AND SERVICES OR THE SERVER
            THAT MAKES OUR SITE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
            COMPONENTS OR THAT OUR SERVICES OR ANY SERVICES OR ITEMS OBTAINED
            THROUGH OUR SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
            AGROLEARN HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
            EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT
            LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND
            FITNESS FOR PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT ANY
            WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER THE APPLICABLE
            LAW.
          </li>

          <li>
            <b>Limitation Of Liability </b>
            <br />
            EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD AGROLEARN AND OUR
            OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY
            INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE,
            HOWEVER IT ARISES (INCLUDING LEGAL FEES AND ALL RELATED COSTS AND
            EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF
            ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED),
            WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS
            ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT,
            INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR
            PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY
            YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR
            REGULATIONS, EVEN IF AGROLEARN HAS BEEN PREVIOUSLY ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS
            LIABILITY FOUND ON THE PART OF AGROLEARN, IT SHALL BE LIMITED TO THE
            AMOUNT PAID FOR THE SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE
            BE CONSEQUENTIAL OR PUNITIVE DAMAGES.
          </li>

          <li>
            <b>Termination </b>
            <br />
            15.1 We may terminate or suspend Your account and bar access to our
            Site and Services immediately, without prior notice or liability,
            under our sole discretion, for any reason whatsoever and without
            limitation, including but not limited to a breach of these Terms.{" "}
            <br />
            15.2 If You wish to terminate Your account, You may simply
            discontinue using our Site and Services. <br />
            15.3 All provisions of these Terms which by their nature should
            survive termination shall survive termination, including, without
            limitation, intellectual property rights provisions, warranty
            disclaimers, indemnity and limitations of liability. <br />
          </li>

          <li>
            <b>Governing Law </b>
            <br />
            16.1 These Terms shall be governed and construed under the laws of
            the Federal Republic of Nigeria, without regard to its conflict of
            law provisions. <br />
            16.2 The parties shall endeavour to resolve all disputes, claims,
            misunderstanding, or difference arising out of or in connection with
            these Terms, including any question regarding its existence,
            enforceability, validity, interpretation, or termination (“Dispute”)
            by amicable settlement. <br />
            16.3 If and to the extent that, any such Dispute is not amicably
            settled within 30 calendar days of the commencement of the
            negotiation, or such further period as the parties shall agree in
            writing, such time not exceeding 15 calendar days, the dispute shall
            be referred to and finally resolved by arbitration under the
            Arbitration and Conciliation Act Chapter A18, Laws of the Federation
            of Nigeria 2004, which rules are deemed to be incorporated by
            reference into this clause. <br />
            16.4 All arbitration proceedings shall be in English Language. Each
            party shall bear its own costs in relation to the arbitration
            proceedings, unless otherwise determined by the arbitral tribunal.
            <br />
            16.5 The parties agree to keep the contents of the arbitration
            proceedings strictly confidential, unless that information is
            already in the public domain (otherwise than through a breach of
            this clause) or if that information is required to be disclosed: (a)
            by applicable laws or regulations; (b) by any of the parties to
            their professional advisers or insurers, where they require that
            information to provide their services to them; or (c) to protect or
            pursue a legal right or to enforce or challenge an award in legal
            proceedings. <br />
            16.6 The arbitral award shall be final and binding on the parties.
          </li>

          <li>
            <b>Assignment and Sub-Contracting</b> <br />
            17.1 The User shall not, without the prior written consent of
            Agrolearn, assign, transfer, charge, sub-contract or deal in any
            other manner with all or any of its rights or obligations under
            these Terms, such consent may be withheld in Agrolearn's sole
            discretion. <br />
            17.2. Agrolearn may at any time assign, transfer, charge,
            sub-contract or deal in any other manner with all or any of its
            rights or obligations under these Terms.
          </li>

          <li>
            <b>No Partnership or Agency</b> <br />
            Nothing in these Terms is intended to or shall operate to create a
            partnership between the parties, or authorize either party to act as
            agent for the other, and neither party shall have the authority to
            act in the name or on behalf of or otherwise to bind the other in
            any way (including, but not limited to, the making of any
            representation or warranty, the assumption of any obligation or
            liability and the exercise of any right or power) until a separate
            agreement is entered into between the parties.
          </li>

          <li>
            <b>Third Party Rights</b> <br />
            These Terms do not confer any rights on any person or party (other
            than the parties to these Terms).
          </li>

          <li>
            <b>Notices</b> <br />
            20.1 Any notice required to be given under these Terms shall be in
            writing and shall be delivered by hand or sent by post or email to
            the other party at its address on our Site or the email:
            <a
              classname="text-main-green underline"
              href="mailto:help@agrolearn.org"
            >
              help@agrolearn.org.
            </a>{" "}
            <br />
            20.2 Where applicable, each party shall be responsible for its own
            charges, costs or expenses incurred for sending any notice or
            communication to the other party.
          </li>

          <li>
            <b>Changes to our Site and Services </b> <br />
            We reserve the right to withdraw or amend our Site and Services, and
            any service or material we provide via our Site and Services, in our
            sole discretion without notice. We will not be liable if for any
            reason all or any part of our Site and Services are unavailable at
            any time or for any period. From time to time, we may restrict
            access to some parts or the entirety of our Site and Services to
            Users.
          </li>

          <li>
            <b>Amendments to these Terms </b> <br />
            22.1 We may amend these Terms at any time by posting the amended
            terms (the “Revised Terms”) on our Site. It is Your responsibility
            to review these Terms periodically. <br />
            22.2 Your continued use of our Site and Services following the
            posting of the Revised Terms means that You accept and agree to the
            changes. You are expected to check this page frequently so that You
            are aware of any changes, as they are binding on You. <br />
            22.3 By continuing to access or use our Site and Services after any
            revisions become effective, You agree to be bound by the Revised
            Terms. If You do not agree to the Revised terms, You are no longer
            authorized to use our Site and Services.
          </li>

          <li>
            <b>Waiver</b> <br />
            No failure or delay by Agrolearn to exercise any right or remedy
            provided under these Terms or by law shall constitute a waiver of
            that or any other right or remedy, nor shall it prevent or restrict
            the further exercise of that or any other right or remedy. No single
            or partial exercise of such right or remedy shall prevent or
            restrict the further exercise of that or any other right or remedy.
          </li>

          <li>
            <b>Severability </b>
            <br />
            If any provision of these Terms is held by a court or other tribunal
            of competent jurisdiction to be invalid, illegal or unenforceable
            for any reason, such provision shall be eliminated or limited to the
            minimum extent such that the remaining provisions of these Terms
            will continue in full force and effect.
          </li>

          <li>
            <b>Entire Agreement </b>
            <br />
            25.1 These Terms shall constitute the entire terms and conditions
            between the parties and supersedes and extinguishes all previous
            terms and conditions, promises, assurances, warranties,
            representations and understandings between them, whether written or
            oral, relating to its subject matter. <br />
            25.2. The User acknowledges that in accepting these Terms it does
            not rely on, and shall have no remedies in respect of, any
            statement, representation, assurance or warranty (whether made
            innocently or negligently) that is not set out in these Terms.
          </li>

          <li>
            <b>Acknowledgement </b> <br />
            BY USING OUR SITE AND SERVICES OR OTHER SERVICES PROVIDED BY US, YOU
            ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS AND AGREE TO BE BOUND BY
            THEM.
          </li>

          <li>
            <b>Contact Us </b> <br />
            Please send Your feedback, comments, requests for technical support:{" "}
            <br />
            By email: <a
            className='text-main-green underline font-medium'
              href="mailto:help@agrolearn.org"
            >
              help@agrolearn.org.
            </a>{" "}<br />
            By visiting this page on our website: <a href="https://agrolearn.org/" className='text-main-green underline font-medium'>https://agrolearn.org/</a>. <br />
            By phone number: <a className='text-main-green underline font-medium' href="tel:+2348069072839">+234 (0)806 907 2839</a> <br />
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Terms;
