import React, {useEffect} from "react";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);
  return (
    <section className={`font-face-hl pt-[7rem] md:pt-[2rem]`}>
      <div
        className={`bg-main-green  py-12 px-8 text-center h-[250px] md:h-[371px] flex items-center justify-center md:px-16 mb-6`}
      >
        <div className={`mt-10 md:mt-16 `}>
          <h1
            className={`font-face-mg font-bold text-[40px] text-heading leading-[79.45px] mb-2 md:text-[50px] md:leading-[158.9%]`}
          >
            Privacy Policy
          </h1>
        </div>
      </div>

      <div className=" px-8 lg:px-16 text-[18px] leading-[35px] py-10">
        <h4 className="text-[25px] font-semibold">Welcome to Agrolearn! </h4>
        <p className="">
          At Agrolearn, we understand that your privacy is important. We respect
          and value the privacy of everyone who visits www.agrolearn.com (our
          “Site”) on their devices. Our Privacy Policy governs your visit to our
          Site and explains how we collect, safeguard and disclose the
          information that results from your use of our Site.
          <br />
        </p>
        <br></br>

        <p>
          We only collect and use your Data as described in this Privacy Policy
          (the "Policy") and as permitted by applicable data protection
          regulations. Please read this Policy carefully and ensure that you
          understand it. By using our Site, you agree to the collection and use
          of your information in accordance with this Policy. This Policy should
          be read together with our Terms of Use.
          <br />
        </p>

        <p>
          Our Site is owned and operated by Agrolearn Limited, a company
          registered in Nigeria with offices at Rostal Suite, Press House 27,
          Acme Road, Agidingbi, Ikeja, Lagos State, Nigeria ("Agrolearn Limited
          ", or “Agrolearn” "we", "us" or "our"). For the purposes of the
          Nigerian Data Protection Regulation 2019, we are the Data Controller
          which means that we are responsible for determining the purposes for
          which and means of how your Personal Data is processed.
        </p>
        <br />
        <p>
          Our Terms of Use (“Terms”) govern all use of our Site and together
          with the Privacy Policy constitutes your agreement with us
          (“agreement”). .{" "}
        </p>
        <br />

        <p>
          This Policy describes how Agrolearn processes your Personal Data, but
          it may not address all possible data processing scenarios. This Policy
          is a guide to help you understand:
        </p>
        <br />
        <ul className="list-decimal space-y-2 text-[18px] pb-6 px-4">
          <li>The Information We Collect;</li>
          <li>Cookies</li>
          <li>How We Collect and Use Your Data;</li>
          <li>Consent and Access Rights;</li>
          <li>User Responsibility;</li>
          <li>Data Security;</li>
          <li>Link to Third Party Sites;</li>
          <li>Third Party Access;</li>
          <li>Violation of Privacy;</li>
          <li>Marketing</li>
          <li>Transfer of Personal Data Outside the Country;</li>
          <li>Data Retention;</li>
          <li>How to Contact Us; and</li>
          <li>Changes to this Privacy Policy.</li>
        </ul>

        <ul className="list-decimal space-y-4 text-[18px] px-4">
          <li>
            <b>The Information We Collect </b>
            <br />
            1.1 The type of personal information which we collect and which may
            be processed by us includes but is not limited to your name, home
            address, email address, telephone number, date of birth, username
            and passwords, your account information such as your unique customer
            identification, payment method information, marketing preferences,
            complaints details, financial information, transaction history with
            us, communications on our Site, how you use our Site, your device
            information, your response to advertising and direct marketing
            campaigns and other personal data provided by you or third parties
            as well as relevant information which constitute personal
            information (“Personal Information”).
            <br />
            1.2 We may also collect information that your browser sends whenever
            you visit our Site or when you access our Site by or through a
            mobile device (“Usage Data”). This Usage Data may include
            information such as your computer's Internet Protocol address (e.g.
            IP address), browser type, browser version, the pages of our Site
            that you visit, the time and date of your visit, the time spent on
            those pages, unique device identifiers and other diagnostic data.
            <br />
            When you access Service with a mobile device, this Usage Data may
            include information such as the type of mobile device you use, your
            mobile device unique ID, the IP address of your mobile device, your
            mobile operating system, the type of mobile Internet browser you
            use, unique device identifiers and other diagnostic data.
            <br />
            1.3 We may also use and store information about your location if you
            give us permission to do so (“Location Data”). We use this data to
            provide features of our Site, to improve and customise our Site.
            <br />
            You can enable or disable location services when you use our Site at
            any time by way of your device settings. <br />
            1.4 Personal Information, Usage Data and Location Data are
            collectively referred to in this Policy as Personal Data.
          </li>

          <li>
            <b>Cookies</b>
            <br></br>
            We use Cookies on our Site to personalize content, ensure that you
            have a seamless experience when you visit our Site and to improve
            our Site. Cookies are small text files that are placed on your
            device when you visit our site. These files are read by our Site
            each time you return to our Site. They contain a small amount of
            data which may include an anonymous unique identifier. They allow
            our Site to remember your device and how you interacted with our
            Site.
            <br />
            You can instruct your browser to refuse all cookies or to indicate
            when a cookie is being sent. However, if you do not accept cookies,
            you may not be able to use some portions of our service. Examples of
            Cookies we use: <br />
            (a) Session Cookies: We use Session Cookies to operate our Site;
            <br />
            (b) Preference Cookies: We use Preference Cookies to remember your
            preferences and various settings;
            <br />
            (c) Security Cookies: We use Security Cookies for security purposes;
            and
            <br />
            (d) Advertising Cookies: Advertising Cookies are used to serve you
            with advertisements that may be relevant to you and your interests.{" "}
            <br />
            By accessing or using our Site, you consent to the placement of
            cookies on your device. If you prefer to disable cookies, you may
            control how your browser responds to cookies by adjusting the
            privacy and security settings of your web browser. If you would like
            to delete cookies or instruct your web browser to delete or refuse
            cookies, please visit the help pages of your web browser. Please
            note, however, that if you delete cookies or refuse to accept them,
            you might not be able to use all of the features we offer, you may
            not be able to store your preferences, and some of the pages on our
            Site may not display properly.
            <br />
            In addition to our own cookies, we may also use various third-party
            cookies to report usage statistics of our Site, deliver
            advertisements on and through our Site, as well as carry out other
            activities.
          </li>

          <li>
            <b>How We Collect and Use Your Data </b>
            <br />
            3.1 Agrolearn may collect Personal Data from you, your authorised
            representatives, third parties or from publicly available sources
            including but not limited to, government department or agencies,
            public registries, social media, websites and publications.
            <br />
            3.2 When you send emails or other communication to Agrolearn, we may
            retain those communications for record purposes and in order to
            process your inquiries, respond to your requests and improve our
            services. When you access Agrolearn’s services, our servers
            automatically record information that your browser sends whenever
            you visit our Site.
            <br />
            3.3 We use the above-mentioned Personal Data for the following
            purposes:
            <br />
            (a) to provide and maintain our Site; (b) setting up and managing
            your account and to communicate with you; (c) to allow you to
            participate in interactive features of our Site when you choose to
            do so; (d) to comply with legal and/or regulatory requirements; (e)
            to gather analysis or valuable information so that we can improve
            the services that we offer you; (f) to provide customer support; (g)
            to help us make decisions in order to prevent fraudulent and
            unlawful practices on our Site; (h) for our day-to-day operations
            and administrative purposes; (i) to monitor the usage of our Site;
            (j) to carry out our obligations and enforce our rights arising from
            any contracts entered into between you and us, including for billing
            and collection; (k) providing you with marketing information and
            information concerning promotional offers which we offer that are
            similar to those that you have already purchased or enquired about
            by post, email, telephone and other means unless you have opted not
            to receive such information; (l) to detect, prevent and address
            technical issues; (m) for security and internal audit purposes
            including monitoring emails, mailboxes and correspondences; and (n)
            to notify you about changes to our services.
            <br />
            And for such other purposes as may be directed or consented to by
            you; and for any other relevant purposes that are related to the
            purpose for which you submit your Personal Data to us (collectively{" "}
            <b>“Purposes”</b>).
          </li>

          <li>
            <b>Consent and Access Rights</b> <br />
            4.1 We require your consent for the processing of your Personal
            Data. We shall obtain your consent for/to individual matters, where
            any document deals with different matters.
            <br />
            4.2 We can process your Personal Data in the absence of consent
            where:
            <br />
            a. processing is necessary for the performance of a contract to
            which you are a party or in order to take steps at your request
            prior to entering into a contract; <br />
            b. processing is necessary for compliance with a legal obligation to
            which you are subject to; <br />
            c. processing is necessary in order to protect your vital interests
            or that of another natural person; and <br />
            d. processing is necessary for the performance of a task carried out
            in the public interest or in the exercise of official public mandate
            vested in us. <br />
            4.3 If we intend to use your Personal Data for a purpose which is
            different from the Purposes, we will seek your consent prior to the
            use of your Personal Data for that other purpose.
            <br />
            4.4 In the event of any arrangement whereby Agrolearn sells or
            transfers all, or a portion of, its business or assets (including in
            the event of a reorganization, dissolution or liquidation) to third
            parties, you hereby consent that your Personal Data held with
            Agrolearn can be transferred or assigned to third parties who may
            become the controllers and/or processors of your Personal Data that
            was held by Agrolearn prior to such arrangement. Agrolearn shall at
            all times ensure that you are notified when your Personal Data is
            intended to be transferred to third parties in the circumstances
            outlined in this clause.
            <br />
            4.5 No consent shall be sought, given or accepted in any
            circumstance that may engender direct or indirect propagation of
            atrocities, hate, child rights violation, criminal acts and
            anti-social conducts.
            <br />
            4.6 <b>Your rights:</b> <br />
            4.6.1 You may withdraw your consent at any time and may request
            access to your personal information in our possession. The
            withdrawal of your consent shall, however, not affect the lawfulness
            of processing that was based on such consent before its withdrawal.
            <br />
            4.6.2 You can object to the processing of your Personal Data,
            including for marketing purposes.
            <br />
            4. 6.3 You reserve the right to request the modification or
            amendment of your Personal Data in our possession. <br />
            4.6.4 You reserve the right to require us to erase your Personal
            Data if (a) the Personal Data is no longer necessary in relation to
            the purposes for which they were collected or processed; (b) you
            withdraw your consent on which the processing is based; (c) you
            object to the processing and there are no overriding legitimate
            grounds for the processing; (d) the Personal Data has been
            unlawfully processed; and (e) the Personal Data must be erased for
            compliance with a legal obligation in Nigeria. <br />
            4.6.5 You may request for a copy of the Personal Data we hold on you
            in a structured, commonly used and machine readable format. This
            request is generally free. If, however, your request is repetitive
            or excessive, we may refuse to comply or we may charge you a fee.
            <br />
            4.7 You reserve the right to request the modification or amendment
            of your Personal Information in our possession.
            <br />
            4.8 In all cases of access or modification/amendment of Personal
            Information, we shall request sufficient identification to enable us
            to confirm that you are the owner of the data sought to be accessed
            or modified/amended.
            <br />
          </li>

          <li>
            <b>User Responsibility</b> <br />
            You are required to familiarise yourself with this Policy and to
            ensure that the information you provide to us is complete, accurate
            and up to date.
          </li>

          <li>
            <b>Data Security </b>
            <br />
            6.1 Agrolearn implements and maintains appropriate safeguards to
            protect Personal Data, taking into account in particular the risks
            to you, presented by unauthorised or unlawful processing or
            accidental loss, destruction of, or damage to your Personal Data.
            <br />
            6.2 Safeguarding will include the use of encryption and
            pseudonymisation where appropriate. It also includes protecting the
            confidentiality (i.e. that only those who need to know and are
            authorised to use Personal Data have access to it), integrity and
            availability of the Personal Data. We regularly evaluate and test
            the effectiveness of those safeguards to ensure security of our
            processing of Personal Data.
            <br />
            6.3 You should be aware, however, that no method of transmission
            over the Internet or method of electronic storage is completely
            secure. While we strive to protect the security of your information
            and are constantly reviewing and enhancing our information security
            measures, we cannot guarantee absolute security. Agrolearn,
            therefore, accepts no liability for any damage or loss, however
            caused, in connection with transmission over the Internet or
            electronic storage.
          </li>

          <li>
            <b>Links to third party sites </b>
            <br />
            Our Site may contain links to other websites owned and operated by
            third parties. These links are provided for your information and
            convenience only and are not an endorsement by Agrolearn of the
            content of such linked websites or third parties. The information
            that we collect from you will become available to these websites if
            you click the link to the websites. These linked websites are
            neither under our control nor our responsibility. Agrolearn,
            therefore, makes no warranties or representations, express or
            implied about the safety of such linked websites, the third parties
            they are owned and operated by and the suitability or quality of
            information contained on them. This Policy does not apply to these
            websites, thus, if you decide to access these linked third party
            websites and/or make use of the information contained on them, you
            do so entirely at your own risk. Agrolearn accepts no liability for
            any damage or loss, however caused, in connection with accessing,
            the use of or reliance on any information, material, products or
            services contained on or accessed through any such linked websites.
            We advise that you contact those websites directly for information
            on their privacy policy, security, data collection and distribution
            policies.
            <br />
          </li>

          <li>
            <b>Third Party Access</b>
            <br />
            8.1 Agrolearn only shares Personal Data with other companies,
            entities or individuals in the following limited circumstances:
            <br />
            (a) We have your consent.
            <br />
            (b) We provide such information to other professional advisers or
            other trusted businesses or persons for the purpose of processing
            Personal Data on our behalf. We require that these parties agree to
            process such information based on our instructions and in compliance
            with this Policy and any other appropriate confidentiality and
            security measures.
            <br />
            (c) We have a good faith belief that access, use, preservation or
            disclosure of such information is reasonably necessary to (a)
            satisfy any applicable law, regulation, legal process or enforceable
            governmental request, (b) enforce applicable terms of service,
            including investigation of potential violations thereof, (c) detect,
            prevent, or otherwise address fraud, security or technical issues,
            or (d) protect against imminent harm to the rights, property or
            safety of Agrolearn , its users or the public as required or
            permitted by law.
            <br />
            8.2 Agrolearn is at all times, responsible for the security and
            appropriate use of that data as long as it remains with Agrolearn.
            <br />
          </li>

          <li>
            <b>Violation of Privacy</b>
            <br />
            9.1 We have put in place procedures to deal with any suspected
            Personal Data breach and will notify you of any breach to your
            Personal Data and will let you know the steps we have taken to
            remedy the breach and the security measures we have applied to
            render your Personal Data unintelligible.
            <br />
            9.2 All suspected breach of Personal Data will be remedied within 1
            (one) month from the date of the report of the breach.
            <br />
            9.3 If you know or suspect that a Personal Data breach has occurred,
            you should immediately contact the Agrolearn team at
            [dpo@agrolearn.org].
            <br />
            9.4 Agrolearn will not be responsible for any Personal Data breach
            which occurs as a result of:
            <br />
            (a) an event which is beyond the control of Agrolearn; (b) an act or
            threats of terrorism; (c) an act of God (such as, but not limited to
            fires, explosions, earthquakes, drought, tidal waves and floods)
            which compromises Agrolearn’s data protection measures; (d) war,
            hostilities (whether war be declared or not), invasion, act of
            foreign enemies, mobilisation, requisition, or embargo; (e)
            rebellion, revolution, insurrection, or military or usurped power,
            or civil war which compromises Agrolearn’s data protection measures;
            (f) the transfer of your Personal Data to a third party on your
            instructions; and (g) the use of your Personal Data by a third party
            designated by you.
          </li>

          <li>
            <b>Marketing </b> <br />
            You can tell us whether or not you wish to be contacted for
            marketing purposes and, if so, how we can contact you. We will
            obtain this information from you when we collect your Personal Data
            and will ask you what kind of communication you would like to
            receive from us. You can opt-out from receiving marketing
            communications from us at any time by following the instructions
            below:
            <br />
            (i) Email marketing: to opt-out from emails, use the unsubscribe
            link provided within any email you receive or manage your
            preferences through the “Account Details” section of our Site.{" "}
            <br />
            (ii) SMS Marketing: to opt-out from SMS, you can use the STOP code
            provided in any SMS you receive or manage your preferences through
            the “Account Details” section of our Site. <br />
            Please be aware it may take up to 28 (twenty-eight) days for your
            request to take effect. Please note you may still receive other
            important information about our product and services.
            <br />
          </li>

          <li>
            <b>Transfer of Personal Data Outside the Country </b> <br />
            We primarily store and process the Personal Data of our users in
            Nigeria. However, if we transfer your Personal Data outside of
            Nigeria, we ensure a similar degree of protection is afforded to it
            to safeguard your Personal Data in accordance with applicable data
            protection regulations in Nigeria, which can include by: (i)
            ensuring that your Personal Data is only processed in countries
            which provide adequate data protection laws (in accordance with the
            applicable data protection regulations in Nigeria); (ii) requiring
            recipients to sign up to strong contractual commitments that ensure
            the protection of your Personal Data; or (iii) taking any other
            measures that comply with the applicable data protection regulations
            at the time of transfer. Your consent to this Policy followed by
            your submission of such information represents your agreement to
            that transfer.
            <br />
          </li>

          <li>
            <b>Data Retention </b> <br />
            We will retain your Personal Information only for as long as is
            reasonably necessary for the purposes set out in this Policy for
            which it was collected. We will retain and use your Personal
            Information to the extent necessary to comply with our legal
            obligations as required by the Nigeria Data Protection Regulation
            2019 as may be amended and other applicable laws, to resolve
            disputes, and to enforce our legal agreements and policies.
            <br />
            We will also retain Usage Data for internal analysis purposes. Usage
            Data is generally retained for a shorter period, except when this
            data is used to strengthen the security or to improve the
            functionality of our Site, or we are legally obligated to retain
            this data for longer time periods.
          </li>

          <li>
            <b>How to Contact Us</b>
            <br />
            For any enquiries you have in relation to this Policy or to exercise
            your rights as stated in Clause 4.6 above, please feel free to
            contact our Data Protection Officer at{" "}
            <a
              href="mailto:dpo@agrolearn.org"
              className="text-main-green underline"
            >
              dpo@agrolearn.org
            </a>
          </li>

          <li>
            <b>Changes to this Privacy Policy</b>
            <br />
            We may, from time to time, change or update this Policy in line with
            legal requirements or if our business changes. All changes to this
            Policy will be published on our Site and will become effective on
            publication. We will notify you when we make changes to this Policy.
            <br />
            This Policy was last reviewed and updated on [04/10/2023].
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Privacy;
