import Img1 from "../images/tolu.jpg";
import Img2 from "../images/andrew.jpg";
import Img3 from "../images/Dare.png";
import Img4 from "../images/thomas.png";
import Img5 from "../images/prince.png";
import Img6 from "../images/omolade.jpg";
import Img7 from "../images/T4D_4916-min.jpeg"
import Img8 from "../images/Ujah.jpeg";

const team = [
  {
    id: 0,
    img: Img1,
    name: "Tolulope Aina",
    role: "Founder/ Project Lead",
    desc: "As the dynamic project lead at Agrolearn, Tolulope leads a team of experts in agribusiness, consulting, and brand development. With over 8 years of experience in the field, she is a seasoned strategist and partnership builder who has successfully spearheaded numerous initiatives. Tolulope's expertise and leadership skills make her an asset to Agrolearn, driving the company's success in an ever-changing industry.",
    link: "https://ng.linkedin.com/in/thetolulopeaina"
  },
  {
    id: 1,
    img: Img2,
    name: "Andrew Lala",
    role: "Brand & Marketing Lead",
    desc: "As the Brand & Marketing Lead of Agrolearn, Andrew Lala is responsible for creating and implementing marketing strategies to promote the brand and its products/services. With years of experience in the marketing industry, Andrew brings a wealth of knowledge to Agrolearn, ensuring that the company's message is effectively communicated to its target audience.",
    link: "https://www.linkedin.com/in/andrewlala1/"
  },
  {
    id: 2,
    img: Img3,
    name: "Jesudamilare E. Isaac",
    role: "Programs Lead",
    desc: " Jesudamilare is the program lead at Agrolearn and is responsible for planning and ensuring the team achieves their goals. He has a penchant for data and analytics and is an experienced operations personnel who is obsessed with process optimization. Over the years Damilare has been able to hone his skills across construction, agritech and fintech leading teams across Africa.",
    link: "https://www.linkedin.com/in/ijesudamilare/"
  },
  {
    id: 3,
    img: Img4,
    name: "Thomas Osho",
    role: "Software Lead",
    desc: "As the Software Lead at Agrolearn, Thomas is responsible for planning and developing the technology component of the brand. With years of experience in mobile app and web development across fintech and e-commerce companies in Africa, Thomas has honed his skills in this field.",
    link: "https://www.linkedin.com/in/thomas-osho-668a6088/"
  },
  {
    id: 4,
    img: Img5,
    name: "Iroatu Chibuzor Prince ",
    role: "Software Associate",
    desc: "A software associate on AgroLearn responsible for developing and maintaining software systems that support agriculture education and training, collaborate with team members to design, build, test, troubleshoot technical problems, and ensure that the software meets the needs of AgroLearn's end users.",
    link: "https://www.linkedin.com/in/princeiroatu/"
  },
  {
    id: 5,
    img: Img6,
    name: "Awodein Omolade ",
    role: "Data Analyst Intern",
    desc: "Omolade is a highly accomplished individual with a strong educational background in Mathematics. She has also completed a rigorous Data Science training program as part of the women techsters fellowship. Proficient in a variety of tools such as Excel, Python, SQL, and Power BI, Omolade is well-equipped to analyze complex data sets and identify important trends.",
    link: "https://www.linkedin.com/in/omolade-awodein-9a41a81a5"
  },
  {
    id: 6,
    img: Img7,
    name: "Emmanuel Owoyemi",
    role: "Brand Design Lead",
    desc: "I am a problem-solving designer with a passion for crafting effective visuals that accurately represent a brand's message and values. At AgroLearn, I bring fresh ideas and innovative design solutions to the table. My goal is to make a meaningful contribution to the team by creating designs that not only look great but also work seamlessly to achieve the desired results.",
    link: "https://www.linkedin.com/mwlite/in/emmanuel-owoyemi"
  },
  {
    id: 6,
    img: Img8,
    name: "Ujah Godswill Ofuowoichoyama ",
    role: "Content and Social Media intern",
    desc: "Godswill is a Content and Social Media Manager intern for Agrolearn, responsible for creating engaging content and managing social media accounts to promote educational resources. I work closely with the marketing team to ensure messaging aligns with the organization's values and goals, and have experience in data-driven decision-making to optimize performance.",
    link: "https://www.linkedin.com/in/ujahgodswill"
  },
];

export default team;
