import React from "react";
import boardData from "../../data/board";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Board = () => { 
  return (
    <section className={`py-20 px-8 lg:px-16 mb-[4rem] `} id="Board">
      <h1
        className={`mb-10 font-face-mg text-[38px] leading-[40px]  text-center text-main-green`}
      >
        Board Members
      </h1>
      <div className={`grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4  `}>
        {boardData.map((board) => {
          return (
            <div key={board.id} className={`mb-4`}>
                <div>
                  {/* <img
                  src={board.img}
                  alt=""
                  className={`w-full h-[300px]`}
                /> */}
                  <LazyLoadImage
                  className={`w-[350px] h-[300px]`}
                  style={{objectFit: 'cover'}}
                  effect="blur"
                  alt=''
                  title={board.img}
                  src={board.img} />
                </div>
                
                <div className={`bg-main-green p-2 md:pb-6`}>
                  <h3
                    className={`text-main-lime-green text-center font-bold leading-[32px] text-[16.1483px]`}
                  >
                    {board.name}
                  </h3>
                  <p
                    className={`text-white/70 text-center font-normal leading-[9px] text-[14.4px]`}
                  >
                    {board.role}
                  </p>
                </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Board;
