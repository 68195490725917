import React, {useEffect} from "react";
import { Futux, Cato, Salad,Eupepsia, Gartner, Jomaph, Precision, Tosmat } from "../../intern";
import Form from "../../components/Form";

const Intern = () => {
  useEffect(() => {
    const importTE = async () => {
      await import("tw-elements");
    };
    importTE();
  }, []);


  return (
    <section className={`py-20 bg-team-bg px-8 lg:px-16`}>
      <div>
        <div className={`text-center mb-8`}>
          <h1 className={`text-main-green font-face-mg text-[38px] leading-[41px]`}>Intern With Us</h1>
        </div>
        <div className={`grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 mb-4`}>
          <Futux/>
          <Cato/>
          <Salad/>
          <Eupepsia/>
          <Gartner/>
          <Jomaph/>
          <Precision/>
          <Tosmat/>



        
        </div>

        <div className="w-full mx-auto space-y-4">
        <p className="text-[16px] text-center ">
              If you are interested in any of the above organizations, please click on the button below to fill the form
              </p>
        <button
                  data-te-toggle="modal"
                  data-te-target="#form"
                  data-te-ripple-init
                  className="flex mx-auto items-center space-x-2 text-white border border-main-green bg-main-green px-8 py-2 hover:bg-main-lime-green hover:text-white hover:border-main-lime-green rounded-[4px]"
                >
                  <p className={`text-[16px] font-normal leading-5 `}>Intern Now </p>
                
                </button>

                <div
            data-te-modal-init
            className="fixed top-0 left-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
            id="form"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              data-te-modal-dialog-ref
              className="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px]"
            >
              <div className=" min-[576px]:shadow-[0_0.5rem_1rem_rgba(#000, 0.15)] pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none ">
                <div class=" p-4 text-black/70 space-y-4" data-te-modal-body-ref>
          
               
                  <Form/>
          
    
                </div>
    
                <div class="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                  <button
                    type="button"
                    className="  px-6 pt-2.5 pb-2  text-main-green transition duration-150 ease-in-out focus:outline-none focus:ring-0 "
                    data-te-modal-dismiss
                    data-te-ripple-init
                  >
                    <i class="fa-solid fa-xmark text-[34px]"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intern;
