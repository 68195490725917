import React from "react";
import Img from "../../images/aiyl.svg";
import innov8 from "../../images/Innov8 logo new.png";
import makelab from "../../images/MakeLab logo new.png";
import one from "../../images/one.png"
import Globe from "../../images/AfricaSphere 1.svg";
import Winrock from "../../images/Winrock logo new.png";
import Giz from "../../images/GIZ logo new.png";
import LanguageBg from "../../images/language-bg.png";
import Roddenberry from "../../images/rodden-logo.png"
// import {  Swiper, SwiperSlide } from "swiper/react";
// import { Navigation, Autoplay} from "swiper";
import { LazyLoadImage } from 'react-lazy-load-image-component';


const Institution = () => {

  //const images =[innov8, makelab, one, Winrock, Giz  ]

  return (
    <section className={`mb-6`}>
      <div>
        <div className={`relative  p-4`} style={{backgroundImage: `url(${LanguageBg})`, backgroundRepeat: `no-repeat`, backgroundSize: `cover`}}>

          <div className="flex flex-col items-center mx-auto">
          {/* <img src={Img} alt="" className={`mb-10 pt-6`} /> */}
          <LazyLoadImage
          className={`mb-10 pt-6`}
          style={{objectFit: 'cover'}}
          effect="blur"
          alt=''
          title={Img}
          src={Img} />
      
      <div className={`-mb-4`}>
        <img src={Globe} alt="" className={`w-full h-full`} />
        {/* <LazyLoadImage
          className={`w-full h-full`}
          style={{objectFit: 'cover'}}
          effect="blur"
          alt=''
          title={Globe}
          src={Globe} /> */}
      </div>

          </div>
         
        </div>

        <div className={`py-20 px-4 md:px-[4rem] text-center`}>
          <p className="text-[18px] pb-10">We partner with leading organizations and Institutions.</p>

          <div
            className={`grid grid-cols-2 gap-4  lg:grid-cols-6 lg:w-[80%] mx-auto`}
          >
            <img src={Winrock} alt="" className={`w-24 self-center  mx-auto`} />
            <img src={Giz} alt="" className={`w-24  self-center mx-auto`} />
            <img src={innov8} alt="" className={`w-24 self-center  mx-auto`} />
            <img src={makelab} alt="" className={`w-24 self-center  mx-auto`} />
            <img src={one} alt="" className={`w-24 self-center   mx-auto`} />
            <img src={Roddenberry} alt="" className={`w-26 self-center   mx-auto`} />
            
          </div>
        </div>
      </div>
    </section>
  );
};

export default Institution;
