import React from "react";
import courses from "../data/courses";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Extension = () => {

  return (
    <>
     <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
     {
       courses.filter(course => course.cat && course.cat.includes("Extension")).map((course) => (
        <div key={course.id} className={`mb-6`}> 
      <div>
     
      <LazyLoadImage
        className={`w-full h-[200px] object-cover object-center`}
        style={{objectFit: 'cover'}}
        effect="blur"
        alt={course.img}
        title={course.img}
        src={course.img} />
      </div>

      <div className={`bg-white py-6 font-face-hl px-4 shadow-sm h-[200px]`}>
        <div className="pb-4 flex items-center justify-between text-black/50 text-[10px]">
          <p>{course.status}</p>
          <p>{course.duration}</p>

        </div>
        <button
               className={`pb-4 text-left w-full text-main-green text-[18px] font-bold leading-[27px] mb-4 underline`}
               
              >
              <a
              href={course.url}
              >
                <h1
                 
                >
                  {course.title}
                </h1>
              </a>
              </button>
        <div className={`flex justify-between items-center`}>
         
          <p className={`font-normal text-black/80 text-[14px] text-center leading-5`}>{course.tutor}</p>
        </div>
      </div>
           </div>
       ))
    }
    </div>

    
    </>

  )
}

export default Extension