import React from "react";
import storyData from "../../data/story";
// import { LazyLoadImage } from "react-lazy-load-image-component";

const Story = () => {
  return (
    <section className={` bg-white px-8 pt-[14rem] lg:px-16 md:pt-[13rem] lg:pt-[40rem]`} id="Story">
      <h1
        className={`text-main-green font-normal text-[38px] leading-[40px] font-face-mg pb-10 text-center md:pb-[2.5rem]`}
      >
        Story behind the brand
      </h1>
      <div>
        {storyData.map((story, i) => {
          return (
            <div className={`mb-10 md:flex gap-10 items-center ${story.id === 1 ? `flex-row-reverse` : null} ${story.id === 3 ? `flex-row-reverse` : null}`} key={story.id}>
              <div className={`md:w-[50%]`}>
                <img
                  src={story.img}
                  alt=""
                  className={`w-full lg:h-[450px] rounded-[4px] mb-4`}
                />
                {/* <LazyLoadImage
                  className={`w-full h-[300px]`}
                  style={{objectFit: 'cover'}}
                  effect="blur"
                  alt=''
                  title={story.img}
                  src={story.img} /> */}
              </div>
              <div className={`md:w-[50%] lg:p-12`}>
                <p
                className={` font-[400] text-[18px] leading-[28.6px] text-justify `}
              >
                <span className="font-bold"> {story.text.split(' ')[0]}</span>
                &nbsp;{story.text.split(" ").slice(1).join(" ")}

               
              
              </p>
              </div>
               
            </div>
          );
        })}
        <p
          className={`font-face-hl text-justify text-[18px] leading-[28.6px] pt-10 mb-16`}
        >
          This is our story at Agrolearn. We are committed to helping
          Agricultural enthusiasts like Danladi who sees Agriculture as
          their retirement plan to make their dreams come true. With Agrolearn,
          students like Chidinma are provided with resources and information
          needed to boost their knowlege and internship placements to help propel their career in agriculture and position them for
          global relevance. We want to see farmers like Abayomi thrive in
          agribusiness by teaching them in their local languages the best
          agricultural practices for them to improve and increase their
          earnings. Agrolearn also wants to ensure that managers like Pearl can
          schedule an on-demand online consultancy session with an expert and
          get the technical know-how needed to combat the pressing business
          challenge. Lastly, we want to create an avenue where professionals
          like David with experiences in diverse fields can teach what they
          know, make an impact and earn a lifelong royalty from it.
        </p>
      </div>
    </section>
  );
};

export default Story;
