import React from "react";
import teamData from "../../data/team";
// import { LazyLoadImage } from "react-lazy-load-image-component";

const Team = () => {
  return (
    <section className={`py-28 bg-team-bg px-8 lg:px-16`} id="Team">
      <h1
        className={`mb-10 font-face-mg text-[38px] leading-[40px] text-center text-main-green `}
      >
        Meet our Team 
      </h1>
      <div className={`grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 `}>
        {teamData.map((team) => {
          return (
            <div key={team.id} className={`mb-4`}>
                <div>
                  <img
                  src={team.img}
                  alt=""
                  className={`w-full h-[300px]`}
                />
                  {/* <LazyLoadImage
                    className={`md:w-full lg:w-[500px] lg:h-[320px] md:h-[450px]`}
                    style={{objectFit: 'contain'}}
                    effect="blur"
                    alt=''
                    title={team.img}
                    src={team.img} /> */}
                </div>
                <div className={`bg-main-green p-2 md:p-3 space-y-4 h-[355px]`}>
                  <h3
                  className={`text-main-lime-green text-center font-bold leading-[32px] text-[16.1483px]`}
                >
                  {team.name}
                </h3>
                <p
                  className={`text-white/70 text-center font-normal leading-[9px] text-[14.4px]`}
                >
                  {team.role}
                </p>

                <p
                  className={`text-white/70 text-justify font-normal leading-[19px] text-[12.4px] px-4`}
                >
                  {team.desc}
                </p>

                <div className="flex items-center justify-center">
                  <a href={team.link} target="_blank" rel="noreferrer" className="text-white">
                    <span className="mr-2 text-white">See LinkedIn Profile</span>
                    <i class="fa-brands fa-linkedin text-[20px]"></i>
                  </a>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Team;
