import React, {useState, useRef} from 'react'
import ReactDOM from 'react-dom';

import LogoWhite from "../images/Agrolearn Logo.svg";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";


const Modal = ({show, onClose}) => {


//states for form
const [success, setSuccess] = useState(false);
const [loading, setLoading] = useState("Submit form");



const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const recaptchaRef = useRef(null);


//email js credentials
  const service_id = process.env.REACT_APP_SERVICE_ID_EBOOK;
  const template_id = process.env.REACT_APP_TEMPLATE_ID_EBOOK;
  const public_key = process.env.REACT_APP_PUBLIC_KEY_EBOOK;

 
  //setting up recaptcha token
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [err, setErr] = useState("");
  const onRecaptchaChange = (token) => {
    setRecaptchaToken(token);
    setErr("");
    console.log(token);
  };


  //function that handles form submission
  const onSubmit = async (data) => {
    console.log(data);

    // Send email using EmailJS here and if recaptcha token is met
    if (recaptchaToken) {
      emailjs
        .send(
          service_id,
          template_id,
          {
            name: data.name,
            email: data.email,
          },
          public_key
        )
        .then(
          (result) => {
            
            setSuccess(true);
            console.log(result.text);
            recaptchaRef.current.reset();

            setLoading("Submit form");


          },
          (error) => {
            console.log(error.text);
          }
        );
      setLoading("Message Loading...");
      reset({
        name: "",
        email: "",
      });
    } else {
      setErr("Please complete the reCaptcha");
    }
  };





  return ReactDOM.createPortal(
    
     <div  onClick={onClose} className={`${ show ? 'modal fixed top-0 left-0  h-full w-full  bg-[rgba(0,0,0,0.5)] flex items-center justify-center z-30' : 'hidden' }`}> 

<div className=" translate-y-[-50px]  min-[576px]:shadow-[0_0.5rem_1rem_rgba(#000, 0.15)] min-[576px]:mx-auto min-[576px]:mt-7  min-[576px]:w-[500px] min-[992px]:w-[800px]">

        <div onClick={e => e.stopPropagation()} className='modal-content pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-main-green bg-clip-padding text-current shadow-lg outline-none  text-white '>
        <div
              class=" p-4 text-white space-y-4 flex items-center flex-col justify-center"
              data-te-modal-body-ref
            >
              <div className="py-4">
                <img src={LogoWhite} alt="" srcset="" />
              </div>
              <h1
                style={{
                  textAlign: "center",
                  fontFamily: "mogena",
                  color: "#fff",
                }}
                className="mb-4 text-[18px] lg:text-[35px] "
              >
                Unlock the Secrets to Raising Multi<span className="font-sans">-</span>Million Dollar Financing for
                your Agribusiness
              </h1>

              <form
                onSubmit={handleSubmit(onSubmit)}
                action=""
                className="w-full px-16"
              >
                <div className="mb-3 w-full">
                  <input
                    {...register("name", { required: true })}
                    type="text"
                    className=" border-4 border-main-lime-green w-full font-face-hl  text-black/80 p-2 outline-none placeholder-black/80"
                    placeholder="Fullname"
                    name="name"
                  />
                  {errors.name && (
                    <span className="text-red-500">This field is required</span>
                  )}
                </div>
                <div className="mb-3 w-full">
                  <input
                    {...register("email", { required: true })}
                    type="email"
                    className="border-4 border-main-lime-green   w-full font-face-hl  text-black/80 p-2 outline-none placeholder-black/80"
                    placeholder="Email"
                    name="email"
                  />
                  {errors.email && (
                    <span className="text-red-500">This field is required</span>
                  )}
                </div>

                <div>
                  <ReCAPTCHA
                    onChange={onRecaptchaChange}
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    ref={recaptchaRef}
                  />

                  <p className="text-left text-red-600 mt-4">{err}</p>

                  <button
                    type="submit"
                  
                    className="bg-white border-4 border-main-lime-green  text-main-lime-green px-8 py-1 m-auto flex"
                  >
                    {loading}
                  </button>
                </div>
                {success && (
                  <p className="text-center mt-4">Congratulations, Message sent Successfully! 🎉🎉🎉</p>
                )}
              </form>
   
            </div>


            <div class="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md p-4 ">
              <button
                type="button"
                className="  px-6 pt-2.5 pb-2  text-white transition duration-150 ease-in-out focus:outline-none focus:ring-0 "
                onClick={onClose}
              >
                <i class="fa-solid fa-xmark text-[34px]"></i>
              </button>
            </div>

        </div>
</div>

    </div>,
    document.getElementById('modal-root')
  
   
  )
}

export default Modal