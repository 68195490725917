import React from "react";
//import Logo from "../images/Agrolearn Logo.svg";
//import coursesData from "../data/courses";
//import LearnMore from "./LearnMore";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import { Career, Extension, Processing, Production, Recent, Supply, Marketing } from "../../courses";


const Courses = () => {


  // const cards = coursesData.map(course => {
  //   return <div key={course.id} className={`mb-6`}> 
  //     <div>
  //       <img src={course.img} alt="" className={`w-full h-full`} />
  //     </div>
  //     <div className={`bg-white py-6 font-face-hl`}>
  //       <h1 className={`text-main-green text-[18px] font-bold leading-5 mb-4`}>{course.title}</h1>
  //       <div className={`flex justify-between items-center`}>
  //         <a href={course.url} className={`text-black text-base font-normal leading-5`}>follow <span className={`text-main-green underline text-base font-normal`}>courses.agrolearn.org</span></a>
  //         <p className={`font-normal text-black text-base leading-5`}>{course.name}</p>
  //       </div>
  //     </div>
  //   </div>
  // }) 

  // const renderCards = () => {
  //   return cards;
  // }
  // const learn = () => {
  //   return LearnMore;
  // }

  return (
    <section className={`py-20 bg-team-bg px-8 lg:px-16` }>

        <div className={`text-center mb-8`}>
          <h1 className={`text-main-green font-face-mg text-[38px] leading-[41px] pb-6`}>The best courses in Agriculture</h1>
          <Tabs>
    <TabList className="flex justify-center flex-col lg:flex-row items-center gap-6 font-face-hl pb-6">
          <Tab className="text-[18px] leading-[28.8px] hover:text-main-lime-green focus:outline-none cursor-pointer"> Recent Courses</Tab>
          <Tab  className="text-[18px] leading-[28.8px] hover:text-main-lime-green focus:outline-none cursor-pointer">Marketing</Tab>
          <Tab  className="text-[18px] leading-[28.8px] hover:text-main-lime-green focus:outline-none cursor-pointer">Extension</Tab>
          <Tab  className="text-[18px] leading-[28.8px] hover:text-main-lime-green focus:outline-none cursor-pointer">Supply chain</Tab>
          <Tab  className="text-[18px] leading-[28.8px] hover:text-main-lime-green focus:outline-none cursor-pointer">Career Building</Tab>
          <Tab  className="text-[18px] leading-[28.8px] hover:text-main-lime-green focus:outline-none cursor-pointer">Production</Tab>
          <Tab  className="text-[18px] leading-[28.8px] hover:text-main-lime-green focus:outline-none cursor-pointer">Processing</Tab>
   </TabList>


        <TabPanel>
         <Recent/>
        </TabPanel>

        <TabPanel>
        <Marketing/>
        </TabPanel>

        <TabPanel>
         <Extension/>
        </TabPanel>

        <TabPanel>
        <Supply/>
        </TabPanel>

        <TabPanel>
        <Career/>
        </TabPanel>

        <TabPanel>
         <Production/>
        </TabPanel>


        <TabPanel>
        <Processing/>
        </TabPanel>

      
  </Tabs>

          {/* <div className={`grid grid-cols-1 gap-4 md:grid-cols-4`}>
            <button onClick={renderCards} className={`text-[18px] font-normal leading-[21px]  text-black font-face-hl hover:border-b-2 hover:border-main-green hover:text-main-green`}>Recent Courses</button>
          <button onClick={learn} className={`text-[18px] font-normal leading-[21px]  text-black font-face-hl hover:border-b-2 hover:border-main-green hover:text-main-green`}>Marketing</button>
          <button onClick={renderCards} className={`text-[18px] font-normal leading-[21px]  text-black font-face-hl hover:border-b-2 hover:border-main-green hover:text-main-green`}>Extension</button>
          <button onClick={renderCards} className={`text-[18px] font-normal leading-[21px]  text-black font-face-hl hover:border-b-2 hover:border-main-green hover:text-main-green`}>Processing</button>
          <button onClick={renderCards} className={`text-[18px] font-normal leading-[21px]  text-black font-face-hl hover:border-b-2 hover:border-main-green hover:text-main-green`}>Production</button>
          <button onClick={renderCards} className={`text-[18px] font-normal leading-[21px]  text-black font-face-hl hover:border-b-2 hover:border-main-green hover:text-main-green`}>Supply Chain</button>
          <button onClick={renderCards} className={`text-[18px] font-normal leading-[21px]  text-black font-face-hl hover:border-b-2 hover:border-main-green hover:text-main-green`}>Career Building</button>
          </div> */}
          
        </div>
        {/* <div className={`grid grid-cols-2 gap-4 md:grid-cols-4`}>{cards}</div> */}
  
    </section>
  );
};

export default Courses;
