import Img0 from "../images/graphics.png"
import Img1 from "../images/maize.jpg";
import Img2 from "../images/digital.jpg";
import Img3 from "../images/extension.jpg";
import Img4 from "../images/pexels-photo-3213283 1.svg";

const courses = [
  {
    id: 0,
    img: Img0,
    status: "Free",
    cat: ["Marketing"],
    duration: "1hr 20mins",
    title: "Basics of Graphics Design Using Canva",
    url: "https://courses.agrolearn.org/courses/basics-of-graphics-designing-using-canva/",
    tutor:"Agrolearn"
  },
  {
    id: 1,
    img: Img1,
    status: "Paid",
    cat: ["Processing", "Preservation"],
    duration: "1hr 20mins",
    title: "Methods of Maize Preservation and Storage",
    url: "https://courses.agrolearn.org/courses/maize-storage-and-preservation/",
    tutor:"Agrolearn"
  },
  {
    id: 2,
    img: Img2,
    status: "Paid",
    duration: "1hr 20mins",
    cat: ["Marketing", "Supply Chain"],
    title: "Strategic Digital Marketing for Agribusinesses",
    url: "https://courses.agrolearn.org/courses/strategic-digital-marketing-for-agribusinesses/",
    tutor:"Agrolearn"
  },
  {
    id: 3,
    img: Img3,
    status: "Paid",
    duration: "1hr 40mins",
    cat: ["Career", "Extension"],
    title: "Agricultural Extension",
    url: "https://courses.agrolearn.org/courses/agricultural-extension/",
    tutor:"Agrolearn"
  },
  {
    id: 4,
    img: Img4,
    status: "Paid",
    duration: "2hr 10mins",
    cat: ["Production"],
    title: "Soiless Farming (Hydroponics)",
    url: "https://courses.agrolearn.org/courses/soiless-farming-hydroponics/",
    tutor:"Agrolearn"
  },
];

export default courses;
