import React, { useState, useEffect } from "react";
import Logo from "../images/Agrolearn Logo Straight-06 1.svg";
import { Link, useLocation } from "react-router-dom";

import Modal from "./Modal";


const Nav = () => {



  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");




  //show state for modal
  const [show, setShow] = useState(false);
  useEffect(() => {
  const modalPopup = setTimeout(() => {
    setShow(!show);
   }, 2000);

   return () => {
    clearTimeout(modalPopup);
   }
     // eslint-disable-next-line
  },[]);

  const [openAbout, setOpenAbout] = useState(false);
  const [openKnowledgeHub, setOpenKnowledgeHub] = useState(false);
  const [active, setActive] = useState(false);
  const [close, setClose] = useState(false);

  const handleClick = () => {
    setActive(false);
    setClose(false);
  };

  const handleAboutHover = () => {
    setOpenAbout(true);
  };

  const handleAboutLeave = () => {
    setOpenAbout(false);
  };

  const handleKnowledgeHubHover = () => {
    setOpenKnowledgeHub(true);
  };


  const handleKnowledgeHubLeave = () => {
    setOpenKnowledgeHub(false);
  };

  console.log(close);
  
  return (
    <>
      <nav
        className={`bg-white shadow-md px-8 lg:px-16 py-5  fixed z-20 w-full md:flex justify-between items-center top-0 right-0 ${
          active ? `pb-8 transition ease-in-out delay-150` : null
        }`}
      >
        <div className={`flex justify-between items-center mb-4 md:mb-0 py-4`}>
          <Link onClick={handleClick} to="/">
            <img src={Logo} alt="Agrolearn Logo" target="_self"/>
          </Link>

          <div
            onClick={() => setActive(!active)}
            className={`md:hidden`}
          >
            {active ? (
              <i className="fa-solid fa-xmark text-[24px]"></i>
            ) : (
              <i className="fa-solid fa-bars text-[24px]"></i>
            )}
          </div>
        </div>
        <div
          className={`text-main-green relative  md:w-[70%] mx-auto ${
            active
              ? `flex flex-col my-4 justify-center items-center`
              : `hidden md:flex md:flex-row gap-6 items-center justify-center`
          }`}
        >
          <div
            className={`relative mb-4 md:mb-0 text-left`}
            onMouseEnter={handleAboutHover}
            onMouseLeave={handleAboutLeave}
          >
            <Link
              to="#"
              target="_self"
              className={`${
                splitLocation[1] === "about" ? "text-main-lime-green " : ""
              } text-center space-x-1 hover:text-main-lime-green  mb-4 font-normal text-[18px] lg:text-[16px]`}
            >
              <span>About Us</span>
              {openAbout ? (
                <i className="fa-solid fa-chevron-up text-[14px]"></i>
              ) : (
                <i className="fa-solid fa-chevron-down text-[14px]"></i>
              )}
            </Link>

            <div
              className={`rounded-[4px] font-normal text-[14px] lg:absolute -right-8 z-10 bg-main-green shadow-xl w-[250px] lg:w-[150px] p-5  text-white flex flex-col  justify-start items-start ${
                openAbout ? `flex` : `hidden`
              }`}
            >
              <a
                onClick={handleClick}
                href="/about/#Header"
                target="_self"
                className={`mb-2 hover:text-main-lime-green hover:font-bold`}
              >
                Vision
              </a>
              <a
                onClick={handleClick}
                href="/about/#Story"
                target="_self"
                className={`mb-2 hover:text-main-lime-green hover:font-bold`}
              >
                Brand Story
              </a>
              <a
                onClick={handleClick}
                href="/about/#Team"
                target="_self"
                className={`mb-2 hover:text-main-lime-green hover:font-bold`}
              >
                Team
              </a>
              <a
                onClick={handleClick}
                href="/about/#Board"
                target="_self"
                className={`mb-2 hover:text-main-lime-green hover:font-bold`}
              >
                Board Members
              </a>
            </div>
          </div>

          <div
            className={`relative mb-4 md:mb-0`}
            onMouseEnter={handleKnowledgeHubHover}
            onMouseLeave={handleKnowledgeHubLeave}
          >
            <div className="text-left  space-x-1  hover:text-main-lime-green">
              <a
                href="#."
                target="_self"
                className={`mb-4 hover:text-main-lime-green font-normal text-[18px] lg:text-[16px]`}
              >
                Knowledge Hub
              </a>
              {openKnowledgeHub ? (
                <i className="fa-solid fa-chevron-up  text-[14px]"></i>
              ) : (
                <i className="fa-solid fa-chevron-down text-[14px]"></i>
              )}
            </div>

            <div
              className={`rounded-[4px] font-normal text-[14px] lg:absolute -right-0 z-10 bg-main-green shadow-xl w-[250px] lg:w-[150px] p-5  text-white flex flex-col  justify-start items-start ${
                openKnowledgeHub ? `flex` : `hidden`
              }`}
            >
              <a
                href="https://blog.agrolearn.org/"
                target="_self"
                className={`mb-2 hover:text-main-lime-green hover:font-bold`}
              >
                Blog
              </a>
              <a
                href="#."
                target="_self"
                className={`mb-2 hover:text-main-lime-green hover:font-bold`}
              >
                Media
              </a>
            </div>
          </div>
        </div>

        <div className="buttons flex items-center justify-center gap-8">
          <button
            className={`text-main-green hover:text-white border border-main-green hover:border-main-lime-green hover:bg-[#00EE57]  rounded px-10 py-1 font-normal text-[14px]`}
          >
            <a href="https://courses.agrolearn.org/dashboard/">Login</a>
          </button>
          <button
          onClick={() => setShow(!show)}
            className={`bg-main-green  hover:bg-[#00EE57] text-white border border-main-green hover:border-main-lime-green rounded  px-10 py-1 font-normal text-[14px]`}
          >
            Ebook
          </button>
        </div>
      </nav>

  {/* Custom modal  */}

  <Modal onClose={() => setShow(false)} show={show} />

{/* custom modal end */}

  
      {/* second modal */}
      {/* <div
        data-te-modal-init
        className="fixed top-0 left-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
        id="congrats"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          data-te-modal-dialog-ref
          className="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px]"
        >
          <div className=" min-[576px]:shadow-[0_0.5rem_1rem_rgba(#000, 0.15)] pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-main-green bg-clip-padding text-current shadow-lg outline-none ">
            <div class=" p-4 text-white/70 space-y-4" data-te-modal-body-ref>
              <h2
                className="px-5"
                style={{
                  textAlign: "center",
                  fontFamily: "mogena",
                  color: "#fff",
                  fontSize: "25px",
                }}
              >
                🎉Congratulations🎉
              </h2>
              <p style={{ textAlign: "center", color: "#fff" }}>
                Please check your email inbox for the ebook
              </p>

              <div></div>
            </div>

            <div class="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md p-4 ">
              <button
                type="button"
                className="  px-6 pt-2.5 pb-2  text-white transition duration-150 ease-in-out focus:outline-none focus:ring-0 "
                data-te-modal-dismiss
                data-te-ripple-init
              >
                <i class="fa-solid fa-xmark text-[34px]"></i>
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Nav;
