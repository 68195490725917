import React, {useEffect, useState} from "react";
//import Img from "../HeaderImg/965 1.svg";
import slide1 from "../../images/Enthusiast.jpg";
import slide2 from "../../images/Agripreneurs.jpg";
import slide3 from "../../images/Local-farmers.jpg";
import slide4 from "../../images/Student.jpg";
import slide5 from "../../images/You.jpeg"
//import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Autoplay } from 'swiper';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Swiper styles if you haven't done it already
import 'swiper/swiper.min.css';
// import 'swiper/components/effect-fade/effect-fade.min.css';

SwiperCore.use([EffectFade, Autoplay]);

const Hero = () => {
  useEffect(() => {
    const importTE = async () => {
      await import("tw-elements");
    };
    importTE();
  }, []);

  const data = [
    {
      id: 1,
      title: "Agric Enthusiasts",
      image: slide1
    },
    {
      id: 2,
      title: "Agripreneurs",
      image: slide2
    },
    {
      id: 3,
      title: "Local Farmers",
      image: slide3
    },
    {
      id: 4,
      title: "Students",
      image: slide4
    },
    {
      id: 5,
      title: "You",
      image: slide5
    },

  ]

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
const interval = setInterval(() => setCurrentSlide((prev) => (prev === data.length -1 ? 0 : prev + 1)), 2500);

return() => clearInterval(interval);
  })

  //const images = [slide1, slide2, slide3, slide4, slide5];

  //const words = ["Agric Enthusiasts", "Agripreneurs", "Local Farmers", "Students", "You"];
  
  // const [index, setIndex] = useState(0);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setIndex((index + 1) % words.length);
  //   }, 2500);
  //   return () => clearInterval(interval);
  // }, [index, words.length]);

  return (
    <>
      <section className="font-face-hl pt-[8rem] md:p-0 md:pt-[6rem]">
        <div className="flex flex-col lg:flex-row">
          <div className="lg:w-[50%] flex flex-col justify-center bg-main-green py-[4rem] px-[1.2rem] lg:px-16 md:px-[2rem]">
            <h1 className="font-face-mg font-normal text-[35px] lg:text-[45px] leading-[54px] text-white mb-4">
              Agroknowledge <br />for{" "}
              <span className="text-heading ">{data[currentSlide].title}</span>
            </h1>
            <p className="text-white text-[18px] leading-[32px] font-face-hl mb-8">
              We are equipping the next generation of Agrarian leaders in Africa through online learning.
            </p>
            <div className="grid grid-cols-2 gap-4 lg:w-[380px]">
              <a
              href="https://courses.agrolearn.org/"
                
                className="py-2 px-4 bg-white text-main-green rounded-[4px] hover:bg-main-lime-green hover:text-white"
              >
                Explore Courses
              </a>
              <a
                href="https://courses.agrolearn.org/dashboard/"
                className="py-2 px-4 rounded-[4px] border border-white bg-main-green text-white hover:bg-main-lime-green hover:border-0 text-center"
              >
                Get Started
              </a>
            </div>
          </div>
          <div className="lg:w-[50%] justify-center">
            <div className="h-[300px] lg:h-[500px]">
            <img src={data[currentSlide].image} alt=""  className="w-full h-full" srcset="" />
            </div>
           
            {/* <Swiper
              slidesPerView={1}
              effect="fade"
              loop={true}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              onSlideChange={(swiper) => {
                const newIndex = swiper.realIndex % words.length;
                setIndex(newIndex >= 0 ? newIndex : newIndex + words.length);
              }}
            >
              {images.map((image, i) => (
                <SwiperSlide key={i}>
                  <div className="h-[300px] lg:h-[500px]">
                    <img src={image} alt="" className="w-full h-full" />
                  
                  </div>
                </SwiperSlide>
              ))}
            </Swiper> */}
          </div>
        </div>
      </section>

       {/*  modal */}
       <div
       data-te-modal-init
       className="fixed top-0 left-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
       id="soon"
       tabindex="-1"
       aria-labelledby="exampleModalLabel"
       aria-hidden="true"
     >
       <div
         data-te-modal-dialog-ref
         className="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[550px]"
       >
         <div className=" min-[576px]:shadow-[0_0.5rem_1rem_rgba(#000, 0.15)] pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-main-green bg-clip-padding text-current shadow-lg outline-none ">
           <div class=" p-4 text-white/70 py-[100px]" data-te-modal-body-ref>
             <div className="">
             <h2
               className="px-5 items-center"
               style={{
                 textAlign: "center",
                 fontFamily: "mogena",
                 color: "#fff",
                 fontSize: "35px",
               }}
             >
               🎉 Courses Coming Soon 🎉
             </h2>
            
             </div>
           
         
           </div>

           <div class="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md p-4 ">
             <button
               type="button"
               className="  px-6 pt-2.5 pb-2  text-white transition duration-150 ease-in-out focus:outline-none focus:ring-0 "
               data-te-modal-dismiss
           
             >
               <i class="fa-solid fa-xmark text-[34px]"></i>
             </button>
           </div>
         </div>
       </div>
     </div>

     </>
  );
};

export default Hero;
