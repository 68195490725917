import React from "react";
import Hero_img from "../../images/vision.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Header = () => {
  return (
    <section className={`font-face-hl pt-[7rem] bg-main-green md:pt-[2rem]`} id="Header">
      <div
        className={`bg-main-green py-12 px-8 text-center h-[371px] relative md:h-[636px] md:px-16`}
      >
        <div className={`mt-10 md:mt-16`}>
          <h1
          className={`font-face-mg font-bold text-[40px] text-heading leading-[79.45px] mb-2 md:text-[50px] md:leading-[158.9%]`}
        >
          Our Vision
        </h1>
        <p
          className={`font-face-hl font-normal text-[16px] text-white leading-[28.6px] mb-2 md:w-[500px] mx-auto md:mb-2 md:text-[18px] pb-[2rem]`}
        >
          We are equipping the next generation of Agrarian leaders in Africa
          through online learning.
        </p>
        </div>
        
       <div className={`relative w-[100%] h-[251.4px] z-10 top-0 md:w-[100%] mx-auto md:h-[500px] lg:h-[700px] md:-top- lg:top-0`}>
          {/* <img
          src={Hero_img}
          alt=""
          className={`w-[100%] rounded-[4px] lg:h-[880px] object-fit` }
        /> */}
          <LazyLoadImage
            className={`w-[100%] rounded-[4px] lg:h-[880px] object-fit` }
            style={{objectFit: 'cover'}}
            effect="blur"
            alt=''
            title={Hero_img}
            src={Hero_img} />
        </div>
        
      </div>
    </section>
  );
};
export default Header;
