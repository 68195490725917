import React from "react";

import { Header, Story , Team, Board  } from "../sections/about";

const About = () => {
  return (
    <div>
   
      <Header />
      <Story />
      <Team />
      <Board />
  
    </div>
  );
};

export default About;
