import {
  createBrowserRouter,
  RouterProvider,
  Outlet
} from "react-router-dom"; 
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';



import Footer from "../src/components/Footer";
import Nav from "../src/components/Nav";
import About from "./pages/About";
import Home from "./pages/Home";
import Terms from "./pages/Terms";
import Faq from "./pages/Faq";
import Privacy from "./pages/Privacy";
import PageNotFound from "./pages/PageNotFound";


const Layout = () => {
  return (
    <>
    <Nav/>
    <ToastContainer/>
    <Outlet/>
    <Footer/>
   
    </>
  )
}

const router = createBrowserRouter([
{
  path: '/',
  element: <Layout/>,
  children: [
    {
      path: '/',
      element: <Home/>
    }, 
    
    {
      path: '/about',
      element: <About/>
    },
    {
      path:'/terms-of-use',
      element: <Terms/>
    },
    {
      path: '/faq',
      element: <Faq/>
    },
    {
      path: '/privacy-policy',
      element: <Privacy/>
    }
  ]
},
{
  path: '*',
  element: <PageNotFound/>
},

]);


function App() {
  return (
    <>
     <RouterProvider router={router}/>
    
    </>
  
   
  );
}

export default App;
