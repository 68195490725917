import React, { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";
import {toast} from 'react-toastify'


const Form = () => {
  const [loading, setLoading] = useState("Submit form");

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();



  const watchEmployment = watch("employment");
  const watchSponsorship = watch("sponsorship");
  const recaptchaRef = useRef(null);

  //email js credentials
  const service_id = process.env.REACT_APP_SERVICE_ID_INTERN;
  const template_id = process.env.REACT_APP_TEMPLATE_ID_INTERN;
  const public_key = process.env.REACT_APP_PUBLIC_KEY_INTERN;




  //setting up recaptcha token
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [err, setErr] = useState('')
  const onRecaptchaChange = (token) => {
    setRecaptchaToken(token);
    setErr('')
    console.log(token)
  };



  //function that handles form submission
  const onSubmit = (data) => {
    // Send email using EmailJS here

    if (recaptchaToken){
      emailjs
      .send(
        service_id,
        template_id,
        {
          firstname: data.firstname,
          lastname: data.lastname,
          gender: data.gender,
          email: data.email,
          phone: data.phone,
          age: data.age,
          country: data.country,
          state: data.state,
          education: data.education,
          employment: data.employment,
          sector: data.sector,
          organisation: data.organisation,
          sponsorship: data.sponsorship,
          justification: data.justification,
       
        },
        public_key
      )
      .then(
        (result) => {
          console.log(result.text);
          recaptchaRef.current.reset();
          setLoading("Submit form");
         toast.success("Message sent successfully!🎉🎉🎉");
        
  
        },
        (error) => {
          toast.error(error.text);
        }
      );
    setLoading("Message Loading...");
    reset({
      firstname: "",
      lastname: "",
      gender: "",
      email: "",
      phone: "",
      age: "",
      country: "",
      state: "",
      education: "",
      employment: "",
      sector: "",
      organisation: "",
      sponsorship: "",
      justification: "",
    });
    } else {
      toast.error("Please complete the reCaptcha")
     }



  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        action=""
        className="space-y-4 p-10"
      >
        <div className="font-face-hl text-[16px]  leading-[19.2px] flex items-start flex-col gap-2">
          <label for="firstname">First Name<span className="text-red-600">*</span>:</label>
          <input
            {...register("firstname", { required: true })}
            className="border border-gray-500/25 w-full p-[16px] rounded-[4px] placeholder-[#B8C0CC] outline-none text-gray-700 font-normal"
            type="text"
            name="firstname"
            placeholder="Enter Name"
          />
          {errors.firstname && (
            <span className="text-red-500">This field is required</span>
          )}
        </div>

        <div className="font-face-hl text-[16px]  leading-[19.2px] flex items-start flex-col gap-2">
          <label for="firstname">Last Name<span className="text-red-600">*</span>:</label>
          <input
            {...register("lastname", { required: true })}
            className="border border-gray-500/25 w-full p-[16px] rounded-[4px] placeholder-[#B8C0CC] outline-none text-gray-700 font-normal"
            type="text"
            name="lastname"
            placeholder="Enter Name"
          />
          {errors.lastname && (
            <span className="text-red-500">This field is required</span>
          )}
        </div>

        <div className="font-face-hl text-[16px]  leading-[19.2px] flex items-start flex-col gap-2">
          <label for="gender">Gender<span className="text-red-600">*</span>:</label>
          <select
            className="border border-gray-500/25 w-full p-[16px] rounded-[4px] placeholder-[#B8C0CC] outline-none text-gray-700 font-normal"
            name="gender"
            {...register("gender", { required: true })}
          >
            <option value="" selected disabled>
              Select Option
            </option>
            <option value="male"> Male</option>
            <option value="female">Female</option>
            <option value="others">Others</option>
          </select>

          {errors.gender && (
            <span className="text-red-500">This field is required</span>
          )}
        </div>

        <div className="font-face-hl text-[16px]  leading-[19.2px] flex items-start flex-col gap-2">
          <label for="firstname">Email<span className="text-red-600">*</span>:</label>
          <input
            {...register("email", { required: true })}
            className="border border-gray-500/25 w-full p-[16px] rounded-[4px] placeholder-[#B8C0CC] outline-none text-gray-700 font-normal"
            type="email"
            name="email"
            placeholder="Enter Email"
          />
          {errors.email && (
            <span className="text-red-500">This field is required</span>
          )}
        </div>

        <div className="font-face-hl text-[16px]  leading-[19.2px] flex items-start flex-col gap-2">
          <label for="phone">Phone No<span className="text-red-600">*</span>:</label>
          <input
            {...register("phone", { required: true, maxLength: 14 })}
            className="border border-gray-500/25 w-full p-[16px] rounded-[4px] placeholder-[#B8C0CC] outline-none text-gray-700 font-normal"
            type="tel"
            name="phone"
            placeholder="Enter Phone No:"
          />
          {errors.phone && (
            <span className="text-red-500">This field is required</span>
          )}
        </div>

        <div className="font-face-hl text-[16px]  leading-[19.2px] flex items-start flex-col gap-2">
          <label for="gender">Age<span className="text-red-600">*</span>:</label>
          <select
            className="border border-gray-500/25 w-full p-[16px] rounded-[4px] placeholder-[#B8C0CC] outline-none text-gray-700 font-normal"
            name="age"
            {...register("age", { required: true })}
          >
            <option value="" selected disabled>
              Select Option
            </option>
            <option value="16 - 20"> 16 - 20 </option>
            <option value="21 - 25">21 - 25</option>
            <option value="26 - 30">26 - 30</option>
            <option value="31 and Above">31 and Above</option>
          </select>

          {errors.age && (
            <span className="text-red-500">This field is required</span>
          )}
        </div>

        <div className="font-face-hl text-[16px]  leading-[19.2px] flex items-start flex-col gap-2">
          <label for="country">Country of Residence<span className="text-red-600">*</span>:</label>
          <input
            {...register("country", { required: true })}
            className="border border-gray-500/25 w-full p-[16px] rounded-[4px] placeholder-[#B8C0CC] outline-none text-gray-700 font-normal"
            type="text"
            name="country"
            placeholder="Enter State of Residence"
          />
          {errors.country && (
            <span className="text-red-500">This field is required</span>
          )}
        </div>


        <div className="font-face-hl text-[16px]  leading-[19.2px] flex items-start flex-col gap-2">
          <label for="state">State of Residence<span className="text-red-600">*</span>:</label>
          <input
            {...register("state", { required: true })}
            className="border border-gray-500/25 w-full p-[16px] rounded-[4px] placeholder-[#B8C0CC] outline-none text-gray-700 font-normal"
            type="text"
            name="state"
            placeholder="Enter State of Residence"
          />
          {errors.state && (
            <span className="text-red-500">This field is required</span>
          )}
        </div>

        <div className="font-face-hl text-[16px]  leading-[19.2px] flex items-start flex-col gap-2">
          <label for="gender">Level Of Education<span className="text-red-600">*</span>:</label>
          <select
            className="border border-gray-500/25 w-full p-[16px] rounded-[4px] placeholder-[#B8C0CC] outline-none text-gray-700 font-normal"
            name="education"
            {...register("education", { required: true })}
          >
            <option value="" selected disabled>
              Select Option
            </option>
            <option value="Secondary Level"> Secondary Level</option>
            <option value="Undergraduate">Undergraduate</option>
            <option value="Bachelors Degree">Bachelors Degree</option>
            <option value="Masters Degree">Masters Degree</option>
            <option value="PHD">PHD</option>
            <option value="Others">Others </option>
          </select>

          {errors.education && (
            <span className="text-red-500">This field is required</span>
          )}
        </div>

        <div className="font-face-hl text-[16px]  leading-[19.2px] flex items-start flex-col gap-2">
          <label for="gender">Employment Status<span className="text-red-600">*</span>:</label>
          <select
            className="border border-gray-500/25 w-full p-[16px] rounded-[4px] placeholder-[#B8C0CC] outline-none text-gray-700 font-normal"
            name="employment"
            {...register("employment", { required: true })}
          >
            <option value="" selected disabled>
              Select Option
            </option>
            <option value="Self-Employed"> Self-Employed</option>
            <option value="Unemployed">Unemployed</option>
            <option value="Student">Student</option>
            <option value="Employed">Employed</option>
          </select>

          {errors.employment && (
            <span className="text-red-500">This field is required</span>
          )}
        </div>
        {watchEmployment === "Employed" && (
          <div className="font-face-hl text-[16px]  leading-[19.2px] flex items-start flex-col gap-2">
            <label for="sector">Employment Status<span className="text-red-600">*</span>:</label>
            <select
              className="border border-gray-500/25 w-full p-[16px] rounded-[4px] placeholder-[#B8C0CC] outline-none text-gray-700 font-normal"
              name="sector"
              {...register("sector", { required: true })}
            >
              <option value="" selected disabled>
                Select Option
              </option>
              <option value=" Construction"> Construction</option>
              <option value="Finance">Finance</option>
              <option value="Health">Health</option>
              <option value="Education ">Education</option>
              <option value="Technology">Technology</option>
              <option value="Others">Others</option>
            </select>

            {errors.sector && (
              <span className="text-red-500">This field is required</span>
            )}
          </div>
        )}

        <div className="font-face-hl text-[16px]  leading-[19.2px] flex items-start flex-col gap-2">
          <label for="organisation">Intending organization(s)<span className="text-red-600">*</span>:</label>
          <select
            className="border border-gray-500/25 w-full p-[16px] rounded-[4px] placeholder-[#B8C0CC] outline-none text-gray-700 font-normal"
            name="organization"
            multiple
            {...register("organisation", {
              required: true,
            })}
          >
            <option value="" disabled>
              Select Option
            </option>
            <option value=" FutuX Agri-consult Ltd">
              {" "}
              FutuX Agri-consult Ltd
            </option>
            <option value="Cato Foods and Agro-Allied Global Concepts">
              Cato Foods and Agro-Allied Global Concepts
            </option>
            <option value="Salad Ville">Salad Ville</option>
            <option value="Eupepsia Place Limited ">
              Eupepsia Place Limited
            </option>
            <option value="Gartner Callaway">Gartner Callaway</option>
            <option value="Jomaph Tech Enterprises">
              Jomaph Tech Enterprises
            </option>
            <option value="Precision Field Academy">
              Precision Field Academy
            </option>
            <option value="Tosmat Foods and Agro Products">
              Tosmat Foods and Agro Products
            </option>
          </select>

          {errors.organisation && (
            <span className="text-red-500">Please select at least one option.</span>
          )}
        </div>

        <div className="font-face-hl text-[16px]  leading-[19.2px] flex items-start flex-col gap-2">
          <label for="sponsorship">
            Do you require any kind of sponsorship to take part in this program?<span className="text-red-600">*</span>
          </label>
          <select
            className="border border-gray-500/25 w-full p-[16px] rounded-[4px] placeholder-[#B8C0CC] outline-none text-gray-700 font-normal"
            name="sponsorship"
            {...register("sponsorship", { required: true })}
          >
            <option value="" selected disabled>
              Select Option
            </option>
            <option value="Yes"> Yes</option>
            <option value="No">No</option>
          </select>

          {errors.sponsorship && (
            <span className="text-red-500">This field is required</span>
          )}
        </div>

        {watchSponsorship === "Yes" && (
          <div className=" font-face-hl text-[16px] leading-[19.2px] flex items-start flex-col gap-2">
            <label>If Yes, Kindly provide a justification below<span className="text-red-600">*</span>:</label>
            <textarea
              {...register("justification", { required: true, max: 50 })}
              className="border border-gray-500/25 w-full p-[16px] rounded-[4px] placeholder-[#B8C0CC] outline-none text-gray-700 font-normal"
              name="justification"
              cols="20"
              rows="5"
              placeholder="Start writing..."
            ></textarea>
            {errors.justification && (
              <span className="text-red-500">This field is required</span>
            )}
          </div>
        )}

        <div>
          <div>
            <ReCAPTCHA 
            onChange={onRecaptchaChange}
            sitekey={process.env.REACT_APP_SITE_KEY}
            ref={recaptchaRef}/>
              <p className="text-left text-red-600 mt-4">{err}</p>
            <button
              type="submit"
              className="bg-main-green hover:bg-main-line-green text-white rounded-[4px] py-[12px] px-[24px]"
            >
              {loading}
            </button>
           
          </div>
        
        </div>
      </form>
    </div>
  );
};

export default Form;
