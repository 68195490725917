import Img1 from "../images/1652903606460.jpeg";
import Img2 from "../images/1646677202215.jpeg";
import Img3 from "../images/1518752727249.jpeg";
import Img4 from "../images/1516265667152.jpeg";

const board = [
  {
    id: 0,
    img: Img1,
    name: "Ayo Bankole Akintujoye",
    role: "Strategy & Transformation expert",
  },
  {
    id: 1,
    img: Img2,
    name: "Yetunde Anibaba",
    role: "Management Consulting",
  },
  {
    id: 2,
    img: Img3,
    name: "Taiwo Adeoye",
    role: "Governing Council",
  },
  {
    id: 3,
    img: Img4,
    name: "Avi Ostfeld",
    role: "Senior Adviser",
  },
  
];

export default board;
