import React from "react";
import Img from "../../images/Group 4257.svg";
import Logo from "../../images/Vector.svg";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const LearnMore = () => {
  return (
    <section className={`bg-heading py-24  px-8 lg:px-24`}>
      <div
        className={`grid grid-cols-1  md:grid-cols-2`}
      >
       
        <div className={`py-2 mb-8 md:mb-0`}>
          <h1 className={`font-face-mg text-white text-[32px] pb-6`}>
            Learn with ease at Agrolearn
          </h1>
          <div
            className={`flex  items-center w-[250px] text-[16px] text-white mb-4`}
          >
            <img src={Logo} alt="" className={`w-6 h-6 mr-6`} />
            <p>Available on PC and Mobile</p>
          </div>
          <div
            className={`flex  items-center w-[250px] text-[16px] text-white mb-4`}
          >
            <img src={Logo} alt="" className={`w-6 h-6 mr-6`} />
            <p>Learn at your pace</p>
          </div>
          <div
            className={`flex  items-center w-[250px] text-[16px] text-white mb-8`}
          >
            <img src={Logo} alt="" className={`w-6 h-6 mr-6`} />
            <p>Pay for only what you learn</p>
          </div>
          <div className={``}>
            <a 
            href="https://courses.agrolearn.org/"
              className={`font-face-hl text-base font-bold py-2 px-4 bg-white text-main-green rounded-[4px] hover:bg-main-lime-green hover:text-white`}
            >
              Start Learning For Free
            </a>
          </div>
        </div>

        <div className={`mb-8 md:mb-0`}>
          {/* <img src={Img} alt="" className={`w-full h-full`} /> */}
          <LazyLoadImage
          className={`w-full h-full`}
          style={{objectFit: 'cover'}}
          effect="blur"
          alt=''
          title={Img}
          src={Img} />
        </div>
      </div>
    </section>
  );
};

export default LearnMore;
