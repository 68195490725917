import React, {useEffect} from "react";

import { Hero, Courses, LearnMore, Intern,Institution, Statistics, Testimonial,Cta } from "../sections/home";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);
  return (
    <>

      <Hero />
      <Courses />
      <LearnMore />
      <Intern />
      <Institution />
      <Statistics />
      <Testimonial />
      <Cta />

      
   
    </>
  );
};

export default Home;
