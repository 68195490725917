import React from "react";
import Img from "../../images/next-gen.jpg";
// import { LazyLoadImage } from 'react-lazy-load-image-component';

const Cta = () => {
  return (
    <section className={`p-4 md:p-0 bg-team-bg`}>
      <div className={`grid grid-cols-1 gap-4 md:grid-cols-2`}>
        <div className={`w-full h-fit`}>
          <img src={Img} alt="" className={`w-full h-full`} />
          {/* <LazyLoadImage
            className='inset-0 w-full h-fit object-cover'
            effect="blur"
            alt=''
            title={Img}
            src={Img} /> */}
        </div>

        <div className={`bg-team-bg p-4 font-face-hl md:p-[2rem] lg:p-[4rem] self-center`}>
          <h1
            className={`text-[28px] leading-[35px] lg:text-[38px] lg:leading-[49px] text-main-green font-face-mg mb-[2rem]`}
          >
            Join the next generation of Agrarian Leaders
          </h1>
           <div className={`grid grid-cols-2 gap-4 lg:w-[380px]`}>
            <a 
            href="https://courses.agrolearn.org/"
              className={`py-2 px-4 border border-main-green text-main-green rounded-[4px] hover:bg-main-lime-green hover:border-main-lime-green hover:text-white`}
            >
              Explore Courses
            </a>
            <a 
            href="https://courses.agrolearn.org/dashboard/"
              className={`py-2 px-4 border border-main-green text-main-green rounded-[4px] hover:bg-main-lime-green hover:border-main-lime-green hover:text-white`}
            >
              Get Started
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cta;
