import React, { useEffect } from "react";
import Img7 from "../images/jomaph.jpg";
import logo7 from "../images/jomaph.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Jomaph = () => {
  useEffect(() => {
    const importTE = async () => {
      await import("tw-elements");
    };
    importTE();
  }, []);

  return (
    <>
      <div className={`mb-6`}>
        <div>
          {/* <img
            src={Img7}
            alt=""
            className={`w-full h-[200px] object-cover object-left  rounded-t-[10px]`}
          /> */}
          <LazyLoadImage
            className={`w-[300px] h-[200px] object-cover object-top rounded-t-[10px]`}
            style={{objectFit: 'cover'}}
            effect="blur"
            alt=''
            title={Img7}
            src={Img7} />
        </div>
        <div
          className={`bg-white py-6 font-face-hl p-4 shadow-sm h-[250px] rounded-b-[10px]`}
        >
          <div className={`flex justify-between items-center gap-6 mb-2`}>
            <h1
              className={`text-main-green text-[16px] font-medium leading-5`}
            >
Jomaph Tech Enterprises 
            </h1>

            <img src={logo7} alt="" className={`w-20 h-20 `} />
          </div>

          <div className={``}>
            <p
              className={`font-normal text-black/80 text-[14px] leading-5 mb-4`}
            >
       We are into the fabrication of Agro-allied and industrial machines. 
            </p>

            <button
              data-te-toggle="modal"
              data-te-target="#jomaph"
              data-te-ripple-init
              className="flex items-center space-x-2 text-main-green border border-main-green px-2 py-3 hover:bg-main-green hover:text-white hover:border-main-green rounded-[4px]"
            >
              <p className={`text-[16px] font-normal leading-5 `}>Read More </p>
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>

      <div
        data-te-modal-init
        className="fixed top-0 left-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
        id="jomaph"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          data-te-modal-dialog-ref
          className="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px]"
        >
          <div className=" min-[576px]:shadow-[0_0.5rem_1rem_rgba(#000, 0.15)] pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none ">
            <div class=" p-4 text-black/70 space-y-4" data-te-modal-body-ref>
              <h1 className="text-[35px] font-bold text-center">
              Jomaph Tech Enterprises {" "}
              </h1>
              <p className="text-[16px] text-left  px-8 lg:px-10 ">
              Jomaph Tech Enterprises is an established business that is into the fabrication of Agro-allied and industrial machines such as Poultry and Fish Feedmill machines, Garri processing machine, Rice mill machine, Palm oil Mill, Smoking kiln, Dehydrators, Oil expeller for Soya bean, groundnut, palm kernel, coconut etc, Hammermill, Pulverizer, Plastic Crusher, etc. It was established in 2015 and was Incorporated on the 22nd, June 2018. 

              </p>

              <div className="grid grid-cols-2 px-8 lg:px-10  gap-4 self-center">
                <div className="text-[16px]">
                    <h1><b>Location(s): </b>Ogun State</h1>
                </div>
                <div className="text-[16px]">
                    <h1><b>Internship Duration:</b> 3-6months</h1>
                </div>
                <div className="text-[16px] col-span-2 lg:col-span-1">
                    <h1><b>Benefits:</b> Intern would be equipped on equipment fabrication </h1>
                </div>
                {/* <div className="text-[16px]">
                    <h1><b>Website: </b><a className="text-main-green underline" href=""></a></h1>
                </div> */}
                <div className="text-[16px] col-span-2">
                    <h1><b>Requirements: </b>Extension services, Basics of graphics designing using canva, problem solving skills, Agriculture beyond farming – Unlocking lucrative corporate careers, Mastering Time: Tips and Tricks to Manage Your Time Effectively, Communicate with Confidence: Techniques to Enhance Your Verbal and Written Communication, strategic digital marketing for agribusinesses</h1>
                </div>



              </div>

             
            </div>

            <div class="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
              <button
                type="button"
                className="  px-6 pt-2.5 pb-2  text-main-green transition duration-150 ease-in-out focus:outline-none focus:ring-0 "
                data-te-modal-dismiss
                data-te-ripple-init
              >
                <i class="fa-solid fa-xmark text-[34px]"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Jomaph;
