import Img1 from "../images/Olawale Ojo.jpg";
import Img2 from "../images/Freida Franca.jpg";
//import Img3 from "../images/Olawale Ojo.jpg";
import Img4 from "../images/safiyat.jpeg";
import Img5 from "../images/Newton Ovue.jpg";
import Img6 from "../images/Samuel Aboderin.PNG";
import Img7 from "../images/daniel.jpeg";
// import Img8 from "../images/Olawale Ojo.jpg";

import Img from "../images/abibat.jpeg"


const testimonial = [
  {
    id: 0,
    description:
      " The course was interactive, it gave us the opportunity to think outside the box as regards extension, one thing that stuck with me was the principles that were taught. the course would open your mind to new ways to doing things in regard to Agricultural extension. The course met my expectations. One of the key reasons I took part in the course was to see how I could set up an extension program and I have learnt more than that during this course.",
    name: "Olawale Ojo, Learner",
          img: Img1,
    title: "Extension Services"
  },
  {
    id: 1,
    description:
      "I have no regrets taking this course, its impactful, mind opening, and I am glad I participated. If you have been thinking of building a career in Agricultural extension or do something extension related, it's a practical course that would help you to skill up.",
       name: "Freida Franca, Learner",
    title: "Entension Services",
    img: Img2,
  },
  {
    id: 2,
    description:
      "I learnt how to tell a truthful, compatible, and concise story that can create a connection with my clients. The training was mind-blowing, I learnt how to create my own website and I was able to do a lot of other things in Digital marketing that I never knew was possible.",
       name: "Abibat Eimoga, Learner",
    title: "Strategic Digital Marketing for Agribusinesses",
    img: Img,
  },
  {
    id: 3,
    description:
      " I have not really had any training outside on extension outside the one I had during my degree in the University. This course was amazing, the instructors took us through the current extensions proceduresand also applied it to other sectors outside Agriculture. It was well packaged and practical. ",
       name: "Ahmed Safiyat Beauty, Learner",
    title: "Extension Services",
    img: Img4,
     },
  {
    id: 4,
    description:
      "This course helps increase the capacities of SMEs inAgribusiness to be able to utilize the internet to market their products. This course is for farmers, students, Entrepreneurs, and already established business.",
       name: "Newton Ovie, Instructor",
    title: "Strategic Digital Marketing for Agribusinesses",
    img: Img5,
     },
  {
    id: 5,
    description:
      "I learnt how to position my business and how to build a community. There was always something new to learn in every section of the course and the knowledge gained were practical.",
       name: "Aboderin Samuel, Learner",
    title: "Strategic Digital Marketing for Agribusinesses",
    img: Img6,
     },
  {
    id: 6,
    description:
      "I took part in the training because I had a problem with my business and so far, I am satisfied because the training met my Digital Marketing needs. I learnt how to build a community and know different approach in reaching out to my customers.",
       name: "Adedapo Daniel, Learner",
    title: "Strategic Digital Marketing for Agribusinesses",
    img: Img7,
  },
];

export default testimonial;
