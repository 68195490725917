import React from "react";
import Logo from "../images/Agrolearn Logo.svg";
import Stroke from "../images/footer-stroke.svg";
import { Link } from "react-router-dom";
import Ebook from "../pdf/Ebook.pdf"

const Footer = () => {

  return (
    <footer className={`bg-main-green py-[50px] flex flex-col-reverse lg:flex-row items-center`}>
         <div className={`relative  lg:w-[40%] hidden lg:block`}>
          <img src={Stroke} alt="" className="w-[280px] absolute top-[-132px] left-[-80px]" />
        </div>



      <div className="px-8 lg:px-16 ">
      
      <div className="flex items-start flex-col lg:flex-row gap-10">
      <div className={`lg:w-[30%]`}>
          <h1
            className={`text-main-lime-green text-[18px] leading-[22px] pb-4`}
          >
            Company
          </h1>
          <div className={`text-[14px] leading-[21px] text-white mb-8`}>
            <p className={`pb-4`}>
              Rostal suite, press house 27, Acme Road Agindigbi, Ikeja, Lagos.
            </p>
            <a href="mailto:hello@agrolearn.org" className={`underline`}>
              hello@agrolearn.org
            </a>
          </div>
        </div>

        <div className="lg:w-[30%] ">
          <h1
            className={`text-main-lime-green text-[18px] leading-[22px] pb-4`}
          >
            Links
          </h1>
          <div
            className={`text-[14px]  font-[400] leading-[21px] text-white mb-8 flex flex-col `}
          >
            <Link to="/about" className={`pb-2 hover:text-main-lime-green `}>
              About us
            </Link>
            <Link to="/privacy-policy" className={`pb-2 hover:text-main-lime-green `}>
              Privacy Policy
            </Link>
            <Link to="/terms-of-use" className={`pb-2 hover:text-main-lime-green `}>
              Terms of use
            </Link>
        
            <a href="https://blog.agrolearn.org/" className={`pb-2 hover:text-main-lime-green `}>
              Blog
            </a>
            <Link to="/faq" className={`pb-2 hover:text-main-lime-green `}>
              FAQ
            </Link>
            <a href="https://courses.agrolearn.org/instructor-registration-page/" className={`pb-2 hover:text-main-lime-green ` }>
              Become an instructor
            </a>
            {/* anchor tag for ebook download  */}
            <a href={Ebook} className={`pb-2 hover:text-main-lime-green hidden` } download>
              Ebook download 
            </a>
          </div>
        </div>

        <div className="lg:w-[30%]">
          <h1
            className={`text-main-lime-green text-[18px] leading-[22px] pb-4`}
          >
            Categories
          </h1>
          <div
            className={`text-[14px]  leading-[21px] text-white mb-8 flex flex-col `}
          >
            <a href="https://courses.agrolearn.org/course-category/marketing/" className={`pb-2  hover:text-main-lime-green `}>
              Marketing
            </a>
            <a href="https://courses.agrolearn.org/course-category/extension/" className={`pb-2  hover:text-main-lime-green `}>
              Extension
            </a>
            <a href="https://courses.agrolearn.org/course-category/supply-chain/" className={`pb-2  hover:text-main-lime-green `}>
             Supply Chain
            </a>
            <a href="https://courses.agrolearn.org/course-category/career-building/" className={`pb-2  hover:text-main-lime-green `}>
             Career Building
            </a>
            <a href="https://courses.agrolearn.org/course-category/production" className={`pb-2  hover:text-main-lime-green `}>
           Production
            </a>
            <a href="https://courses.agrolearn.org/course-category/processing/" className={`pb-2  hover:text-main-lime-green `}>
             Processing
            </a>
          </div>
        </div> 
        <div className={` lg:w-[30%]`}>
          <Link to="/">
          <img src={Logo} alt="" />
          </Link>
         
        </div>
      </div>
       
       <div className="h-[1.5px] bg-white mb-8"/>

        <div className={`flex items-center justify-between`}>
          <p className={`text-[14px] leading-[22px] text-white`}>
            &copy; Agrolearn, {new Date().getFullYear()}
          </p>
          <div className={`flex gap-4 items-center justify-end w-1/2 text-main-lime-green  md:w-40`}>
            <a href="http://www.facebook.com/agrolearnn">
            <i class="fa-brands fa-facebook"></i>
            </a>
            <a href="https://twitter.com/agrolearnn">
            <i class="fa-brands fa-x-twitter"></i>
            </a>
            <a href="https://www.instagram.com/agrolearnn/">
            <i class="fa-brands fa-instagram"></i>
            </a>
            <a href="https://www.linkedin.com/company/agrolearn/">
            <i class="fa-brands fa-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
